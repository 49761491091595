
import { computed, defineComponent, Emitter, inject } from "vue";
import BussEventType from "@/types/BussEventType";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { AppMutation } from "@/store/types";

export default defineComponent({
  setup() {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    // const i18n = useI18n();
    const { t, locale } = useI18n();

    const lang = ["zh", "en", "kor"];

    const close = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.close,
        name: "lang",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const setLang = (_lang: string) => {
      locale.value = _lang;
      store.commit(AppMutation.SET_LANG, _lang);
      close();
    };

    const currentLang = computed((): string => store.getters["lang"]);

    return {
      t,
      close,
      setLang,
      lang,
      currentLang,
    };
  },
});
