import axios, { AxiosPromise } from "axios";
import service from "@/utils/service";
import {
  LoginParam,
  NoticeAndLimitParams,
  SingleDeskParams,
} from "@/types/api-params";
import { ACT } from "@/types/types";

/**
 * Ping Test
 * @param line
 * @param url
 * @returns
 */
export function pingServer(line: number, url: string): AxiosPromise<unknown> {
  let api = "";

  if (line === -1) {
    api = `${url}/${process.env.API_EXT}`;
  } else if (line === 1) {
    api = `${process.env.API_URL_LINE1}/${process.env.API_EXT}`;
  } else if (line === 2) {
    api = `${process.env.API_URL_LINE2}/${process.env.API_EXT}`;
  } else if (line === 3) {
    api = `${process.env.API_URL_LINE3}/${process.env.API_EXT}`;
  }

  const params = {
    skey: process.env.APP_SKEY,
    act: 0,
    jm: 1,
  };

  return service({
    baseURL: api,
    method: "POST",
    params,
    responseType: "arraybuffer",
  });
}

/**
 *
 * @param params Login
 * @returns
 */
export function login(params: {
  username: string;
  password: string;
}): AxiosPromise<string> {
  const { username, password } = params;
  return service({
    method: "POST",
    params: new LoginParam(ACT.LOGIN, username, password),
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 *
 * @param params Get Desks
 * @returns
 */
export function getDesks(params: unknown): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 *
 * @param params Get Notification Limit and chips info
 * @returns
 */
export function getNoticeAndLimitInfo(
  params: NoticeAndLimitParams
): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 * Get Desk Info
 * @param params
 * @returns
 */
export function getDeskInfo(params: SingleDeskParams): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 *
 * @param params Get Card info if there is a result
 * @returns
 */
export function getCardInfo(params: any): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 * Place Bet on Dragon Tiger
 * @param params
 * @returns
 */
export function betOnDragonTiger(params: any): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 * Log out user
 * @param params
 * @returns
 */
export function logOut(params: any): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

/**
 *
 * @param params Get user bet log data
 * @returns
 */
export function getLogData(params: any): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}
/**
 * Api Call
 * @param params
 * @returns
 */
export function fetch(params: any): AxiosPromise<string> {
  return service({
    method: "POST",
    params,
    cancelToken: AxiosSource.Source.token,
  });
}

export const AxiosSource = { Source: axios.CancelToken.source() };
