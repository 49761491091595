
import { key } from "@/store";
import { toMoneyFormat } from "@/utils";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup(props, context) {
    const inputValue = ref<number>(0);
    const { t } = useI18n();
    const handleClicked = (
      keyinput:
        | "1"
        | "2"
        | "3"
        | "4"
        | "5"
        | "6"
        | "7"
        | "8"
        | "9"
        | "0"
        | "x"
        | "xx"
    ) => {
      switch (keyinput) {
        case "x":
          {
            if (inputValue.value > 0) {
              let _newVal = parseInt(String(inputValue.value).slice(0, -1));
              if (isNaN(_newVal)) _newVal = 0;
              inputValue.value = _newVal;
            }
          }
          break;
        case "xx": {
          context.emit("confirm", inputValue.value);
          break;
        }
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0": {
          if (String(inputValue.value).length > 8) {
            // toast a message here
            break;
          }
          if (inputValue.value > 0) {
            inputValue.value = parseInt(
              [inputValue.value.toString(), keyinput].join("")
            );
          } else {
            inputValue.value = parseInt(keyinput);
          }
          break;
        }

        default:
          break;
      }
    };

    const formatedInputValue = computed((): string => {
      return toMoneyFormat(inputValue.value);
    });

    const hadleOnClose = () => {
      context.emit("close");
    };

    return {
      handleClicked,
      formatedInputValue,
      hadleOnClose,
      t,
    };
  },
});
