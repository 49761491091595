import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42e9ce8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-picker-root" }
const _hoisted_2 = { class: "wrap" }
const _hoisted_3 = ["value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_date_picker, {
      class: "vc",
      modelValue: _ctx.dateC,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateC) = $event)),
      mode: "date",
      "is-dark": "",
      color: "gray",
      locale: _ctx.lang,
      attributes: _ctx.attrs,
      "min-date": _ctx.minDate,
      disabled: ""
    }, {
      default: _withCtx(({ inputValue, inputEvents }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", _mergeProps({ value: inputValue }, _toHandlers(inputEvents), {
            class: "input",
            placeholder: _ctx.placeHolder,
            disabled: !_ctx.enabled,
            readonly: "readonly"
          }), null, 16, _hoisted_3)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "locale", "attributes", "min-date"])
  ]))
}