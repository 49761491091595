import { Dictionary } from "@/types/dictionary";
import { BaseRoadmapItem } from "./datamodel/BaseRoadmapItem";
import { BeadItems } from "./datamodel/BeadItems";
import { BigItem } from "./datamodel/BigItem";
import { RoadMapData } from "./datamodel/RoadMapData";
import { RoadMapItem } from "./datamodel/RoadMapItem";
import { RoadMapProperties } from "./datamodel/RoadMapProperties";
import { bigColor, RoadType, TypeBead, TypePair } from "./datamodel/types";

/**
 * Parse the Desk Result Data into a readable Roadmap Data
 * @param strData
 */
export function parseRoadMap(strData = ""): RoadMapData {
  const TypeColor = { RED: 0, BLUE: 2 };

  const result = new RoadMapData();

  const parseData = (strData = "") => {
    if (strData.length === 0) return undefined;
    const strArr = strData.split("#");
    strArr.pop(); // remove the last item which is empty string

    const tieMap = new Dictionary<number>();
    const bigItems: BigItem[] = [];
    const bigeyeItems: BaseRoadmapItem[] = [];
    const smallItems: BaseRoadmapItem[] = [];
    const cockroachItems: BaseRoadmapItem[] = [];

    const assestBead = (type: number, pair: number, index: number) => {
      const x = Math.floor(index / 6);
      const y = index % 6;
      const bead: BeadItems = new BeadItems(x, y, type, pair);

      result.beads.push(bead);
    };

    const assestBigItemTemp = (
      index: number,
      list: BigItem[],
      mPost: Dictionary<number>
    ) => {
      let type = TypeBead.GREEN;
      const item = result.beads[index];
      if (item.type === TypeBead.GREEN) {
        if (list.length > 0) {
          const locationX = list.length - 1;
          const locationY = list[locationX].size - 1;
          const key = `${locationX}:${locationY}`;
          if (mPost.ContainsKey(key)) {
            const count = mPost.Item(key) + 1;
            mPost.Add(key, count);
          } else {
            mPost.Add(key, 1);
          }
        }
      } else if (item.type === TypeBead.RED) {
        type = TypeBead.RED;
      } else if (item.type === TypeBead.BLUE) {
        type = TypeBead.BLUE;
      }

      if (type !== TypeBead.GREEN) {
        if (list.length > 0) {
          const lstItem = list.length - 1;
          if (list[lstItem].type === item.type) {
            list[lstItem].addSize();
            list[lstItem].pairs.push(item.pair);
          } else {
            const toAdd = new BigItem(item.type, 0, 0);
            toAdd.pairs.push(item.pair);
            toAdd.addSize();
            list.push(toAdd);
          }
        } else {
          const toAdd = new BigItem(item.type, 0, 0);
          toAdd.pairs.push(item.pair);
          toAdd.addSize();
          list.push(toAdd);
        }
      }
    };

    const assestOtherItemsTemp = (
      startPost: number,
      list: RoadMapItem[],
      refList: RoadMapItem[]
    ) => {
      const size = refList.length;
      const lasPost = size - 1;
      const itemSize = refList[lasPost].size;
      let type = TypeColor.RED;

      if (size > 1 && itemSize > 1) {
        if (
          itemSize - 1 < refList[lasPost - startPost].size ||
          itemSize - 1 > refList[lasPost - startPost].size
        ) {
          type = TypeColor.RED;
        } else {
          type = TypeColor.BLUE;
        }
      } else if (itemSize === 1 && size > startPost + 1) {
        const tempSize = refList[lasPost - 1].size;
        if (
          tempSize < refList[lasPost - (startPost + 1)].size ||
          tempSize > refList[lasPost - (startPost + 1)].size
        ) {
          type = TypeColor.BLUE;
        } else {
          type = TypeColor.RED;
        }
      } else {
        return;
      }

      if (list.length > 0) {
        const tempLastPost = list[list.length - 1];
        if (type === tempLastPost.type) {
          list[list.length - 1].addSize();
        } else {
          const toAdd = new BaseRoadmapItem(type, 0, 0);
          toAdd.addSize();
          list.push(toAdd);
        }
      } else {
        const toAdd = new BaseRoadmapItem(type, 0, 0);
        toAdd.addSize();
        list.push(toAdd);
      }
    };

    /**
     * Assest the BigItems
     * @param paramList
     * @param resultingList
     * @param resultingMap
     */
    const assestBigItems = (
      paramList: RoadMapItem[],
      resultingList: BigItem[],
      resultingMap: Dictionary<number>
    ) => {
      const mapItem = new Dictionary<number>();
      let offsetX = 0;

      for (let i = 0; i < paramList.length; i++) {
        const item = paramList[i];
        const size = item.size;
        let locationY = 0;
        let locationX = i + offsetX;

        for (let j = 0; j < size; j++) {
          let key = `${locationX}:${locationY}`;

          while (mapItem.ContainsKey(key)) {
            if (locationY < 5) {
              locationY += 1;
              const tempKey = `${locationX}:${locationY}`;
              if (mapItem.ContainsKey(tempKey)) {
                locationX += 1;
                locationY -= 1;
              }
            } else if (locationY >= 5) {
              locationY = 5;
              locationX += 1;
            }

            key = `${locationX}:${locationY}`;
            const prevItem = resultingList[resultingList.length - 1];
            if (locationY === 0 && prevItem.type === item.type) {
              offsetX += 1;
            }
          }

          mapItem.Add(key, item.type);
          const tieKey = `${i}:${j}`;
          const tempItem = new BigItem(item.type, locationX, locationY);
          tempItem.pair = item.pairs[j];

          if (resultingMap.ContainsKey(tieKey)) {
            tempItem.tieSize = resultingMap.Item(tieKey);
            tempItem.hasTie = true;
          }
          resultingList.push(tempItem);
        }
      }
    };

    /**
     * Assest the Other Items
     * @param paramList
     * @param resultingList
     */
    const assestOtherItems = (
      paramList: RoadMapItem[],
      resultingList: BaseRoadmapItem[]
    ) => {
      const mapItem = new Dictionary<number>();
      paramList.forEach((item, i) => {
        const size = item.size;
        let locationY = 0;
        let locationX = i;

        for (let j = 0; j < size; j++) {
          let key = `${locationX}:${locationY}`;
          while (mapItem.ContainsKey(key)) {
            if (locationY < 5) {
              locationY += 1;
              const tempKey = `${locationX}:${locationY}`;
              if (mapItem.ContainsKey(tempKey)) {
                locationX += 1;
                locationY -= 1;
              }
            } else if (locationY >= 5) {
              locationY = 5;
              locationX += 1;
            }
            key = `${locationX}:${locationY}`;
          }
          mapItem.Add(key, item.type);

          const toAdd = new BaseRoadmapItem(item.type, locationX, locationY);
          resultingList.push(toAdd);
        }
      });
    };

    strArr.forEach((strValue, key) => {
      const value = parseInt(strValue);
      let bType = Math.floor(value / 10) - 1;
      const pair = value % 10;

      if (bType === TypeBead.DT_TIE) {
        bType = TypeBead.GREEN;
      } else if (bType === TypeBead.TIGER) {
        bType = TypeBead.BLUE;
      } else if (bType === TypeBead.DRAGON) {
        bType = TypeBead.RED;
      }

      if (bType === TypeBead.RED) {
        result.redCount += 1;
      } else if (bType === TypeBead.BLUE) {
        result.blueCount += 1;
      } else if (bType === TypeBead.GREEN) {
        result.tieCount += 1;
      }

      if (pair === TypePair.RED) {
        result.redPairCount += 1;
      } else if (pair === TypePair.BLUE) {
        result.bluePairCount += 1;
      } else if (pair === TypePair.BOTH) {
        result.redPairCount += 1;
        result.bluePairCount += 1;
      }

      result.round += 1;

      assestBead(bType, pair, key);
      assestBigItemTemp(key, bigItems, tieMap);

      const size = bigItems.length;
      if (size > 1 && bType !== TypeBead.GREEN) {
        assestOtherItemsTemp(1, bigeyeItems, bigItems);
      }
      if (size > 2 && bType !== TypeBead.GREEN) {
        assestOtherItemsTemp(2, smallItems, bigItems);
      }
      if (size > 3 && bType !== TypeBead.GREEN) {
        assestOtherItemsTemp(3, cockroachItems, bigItems);
      }
    });

    assestBigItems(bigItems, result.bigs, tieMap);
    assestOtherItems(bigeyeItems, result.bigeyes);
    assestOtherItems(smallItems, result.smalls);
    assestOtherItems(cockroachItems, result.cockroachs);
  };

  parseData(strData);

  return result;
}

/**
 * Fix the Blur of Canvas
 * @param canvas
 */
const fixCanvasSize = (canvas: HTMLCanvasElement): RoadMapProperties => {
  const dpi = window.devicePixelRatio;

  const styleHeight = +getComputedStyle(canvas)
    .getPropertyValue("height")
    .slice(0, -2);
  const styleWidth = +getComputedStyle(canvas)
    .getPropertyValue("width")
    .slice(0, -2);

  //  fix canvas blur
  canvas.setAttribute("height", `${styleHeight * dpi}`);
  canvas.setAttribute("width", `${styleWidth * dpi}`);

  const width = canvas.clientWidth * dpi;
  const height = canvas.clientHeight * dpi;

  const ctx = canvas.getContext("2d", { alpha: false });

  if (!ctx) {
    throw new Error("canvas Context Error");
  }
  return new RoadMapProperties(ctx, width, height, 0, dpi);
};

/**
 * Draw the roadmap into the canvas
 * @param options
 * @param arrs
 * @param roadType
 * @param offsetY
 * @param offsetX
 */
export function drawRoadMap(
  options: RoadMapProperties | undefined,
  arrs: Array<any>,
  roadType = RoadType.BEAD,
  offsetY = 0,
  offsetX = 0,
  gameType = 1,
  t = (s: string): string => {
    return "";
  }
) {
  if (!options) return;

  const { colWidth, ctx, colNum, dpi } = options;

  let radius: number;
  if (roadType === RoadType.BEAD) {
    radius = colWidth / 2;
  } else if (roadType === RoadType.BIG) {
    radius = colWidth / 4;
  } else {
    radius = colWidth / 8;
  }

  // color vars
  const RED = "#c70004";
  const BLUE = "#0000fe";
  const GREEN = "#00c40c";

  const count = arrs.length;
  if (count === 0) {
    return;
  }

  const scroll = arrs[count - 1].x - (colNum - 1);

  try {
    arrs.forEach((item, i) => {
      const { type, x, y, pair, tieSize } = item;

      let inRange = false;
      let scrollX = x;

      if (i === arrs.length - 1 && options.isPre && scrollX >= scroll) {
        return;
      }

      if (scrollX >= scroll) {
        inRange = true;
        if (scroll > 0) {
          scrollX = scrollX - scroll;
        }

        if (scrollX > colNum - 1) {
          return;
        }
      }

      if (inRange) {
        const pointY = y * radius * 2 + offsetY;
        const pointX = scrollX * radius * 2 + offsetX;

        if (roadType === RoadType.BEAD) {
          let color = RED;
          let label = "";
          if (type === TypeBead.RED) {
            color = RED;
            // label = '庄'
            label = gameType === 1 ? t("bankerabt") : t("dragonabt");
          } else if (type === TypeBead.BLUE) {
            color = BLUE;
            // label = '閑'
            // 闲
            label = gameType === 1 ? t("playerabt") : t("tigerabt");
          } else {
            color = GREEN;
            label = t("tieabt");
          }

          ctx.lineWidth = options.lineWidth * dpi;
          ctx.fillStyle = color;
          // ctx.strokeStyle = '#000'
          ctx.beginPath();
          ctx.arc(
            radius + pointX,
            radius + pointY,
            (colWidth / 2) * 0.8,
            0,
            2 * Math.PI
          );
          ctx.stroke();
          ctx.fill();
          ctx.closePath();

          // const fontSize = options.fontSize * dpi

          ctx.fillStyle = "#fff";
          ctx.font = `${radius}px yaheilight`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(label, radius + pointX, pointY + radius);

          const drawBluePair = () => {
            ctx.beginPath();
            ctx.lineWidth = options.lineWidth * dpi;
            ctx.fillStyle = BLUE;
            ctx.strokeStyle = "#fff";
            ctx.arc(
              radius + (pointX + radius / 2),
              radius + (pointY + radius * 0.66),
              (colWidth / 2) * 0.2,
              0,
              Math.PI * 2
            );
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
          };

          const drawRedPair = () => {
            ctx.lineWidth = options.lineWidth * dpi;
            ctx.beginPath();
            ctx.fillStyle = RED;
            ctx.strokeStyle = "#fff";
            ctx.arc(
              radius + (pointX - radius / 2),
              radius + (pointY - radius * 0.66),
              (colWidth / 2) * 0.2,
              0,
              Math.PI * 2
            );
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
          };

          if (pair === TypePair.RED) {
            drawRedPair();
          } else if (pair === TypePair.BLUE) {
            drawBluePair();
          } else if (pair === TypePair.BOTH) {
            drawRedPair();
            drawBluePair();
          }
        } else if (roadType === RoadType.BIG) {
          const colwidth = radius * 2;
          // ctx.lineWidth = options.lineWidth * dpi
          ctx.lineWidth = 1.5;

          if (tieSize > 0) {
            // const fontSize = options.fontSize * dpi
            ctx.fillStyle = GREEN;
            ctx.font = `bold ${radius}px Arial`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(`${tieSize}`, radius + pointX, pointY + radius);

            const startX = pointX + colwidth * 0.23;
            const startY = colwidth + pointY - colwidth * 0.0833;
            const toX = pointX + (colwidth - colwidth * 0.021);
            const toY = colwidth + pointY - colwidth * 0.4375;

            ctx.beginPath();
            ctx.strokeStyle = GREEN;
            ctx.moveTo(startX, startY);
            ctx.lineTo(toX, toY);
            ctx.stroke();
            ctx.closePath();
          }

          ctx.strokeStyle = type === bigColor.RED ? RED : BLUE;
          ctx.beginPath();
          //  ctx.arc(radius + pointX, radius + pointY, radius - (1.5 * dpi), 0, 2 * Math.PI);
          ctx.arc(
            radius + pointX,
            radius + pointY,
            (radius - ctx.lineWidth) * 0.8,
            0,
            2 * Math.PI
          );

          ctx.stroke();
          ctx.closePath();

          const drawBluePair = () => {
            ctx.beginPath();
            ctx.fillStyle = BLUE;
            ctx.strokeStyle = "#fff";
            ctx.arc(
              colwidth - radius * 0.3 - 2 + pointX,
              colwidth - radius * 0.3 - 2 + pointY,
              radius * 0.3,
              0,
              Math.PI * 2
            );
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
          };

          const drawRedPair = () => {
            ctx.beginPath();
            ctx.fillStyle = RED;
            ctx.strokeStyle = "#fff";
            ctx.arc(
              radius * 0.3 + pointX + 2,
              radius * 0.3 + pointY + 2,
              radius * 0.3,
              0,
              Math.PI * 2
            );
            ctx.fill();
            ctx.stroke();
            ctx.closePath();
          };

          if (pair === TypePair.RED) drawRedPair();
          else if (pair === TypePair.BLUE) drawBluePair();
          else if (pair === TypePair.BOTH) {
            drawRedPair();
            drawBluePair();
          }
        } else if (roadType === RoadType.BIGEYE) {
          // ctx.lineWidth = options.lineWidth * dpi
          ctx.lineWidth = 1.5;
          ctx.strokeStyle = type === bigColor.RED ? RED : BLUE;
          ctx.beginPath();
          ctx.arc(
            radius + pointX,
            radius + pointY,
            radius - 1 * dpi,
            0,
            2 * Math.PI
          );
          ctx.stroke();
          ctx.closePath();
        } else if (roadType === RoadType.SMALL) {
          ctx.fillStyle = type === bigColor.RED ? RED : BLUE;
          ctx.strokeStyle = type === bigColor.RED ? RED : BLUE;
          ctx.beginPath();
          ctx.arc(
            radius + pointX,
            radius + pointY,
            radius - 1 * dpi,
            0,
            2 * Math.PI
          );
          ctx.fill();
          ctx.stroke();
          ctx.closePath();
        } else if (roadType === RoadType.COCKROACH) {
          // const lineWidth = options.lineWidth * dpi
          const lineWidth = 2;

          const colwidth = radius * 2;
          ctx.lineWidth = lineWidth;
          ctx.lineCap = "round";
          ctx.strokeStyle = type === bigColor.RED ? RED : BLUE;
          ctx.beginPath();
          ctx.moveTo(pointX + lineWidth, colwidth + pointY - lineWidth);
          ctx.lineTo(pointX + colwidth - lineWidth, pointY + lineWidth);
          ctx.stroke();
          ctx.closePath();
        }
      }
    });
  } catch {
    console.log("Canvas Is Too Small Can not draw the road map");
  }

  // ctx.filter = 'none'
}

/**
 * Draw Gridlines on the canvas
 * Normal Roamap GridLines
 * @param canvas
 */
export function drawRoadMapGrid(
  canvas: HTMLCanvasElement,
  beadColCounts = 13
): RoadMapProperties | undefined {
  const dpi = window.devicePixelRatio;
  const styleHeight = +getComputedStyle(canvas)
    .getPropertyValue("height")
    .slice(0, -2);
  const styleWidth = +getComputedStyle(canvas)
    .getPropertyValue("width")
    .slice(0, -2);

  //fix canvas blur
  canvas.setAttribute("height", `${styleHeight * dpi}`);
  canvas.setAttribute("width", `${styleWidth * dpi}`);

  const width = canvas.clientWidth * dpi;
  const height = canvas.clientHeight * dpi;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return undefined;
  }

  const lineWidth = 1 * dpi;
  // const lineWidth = 1.5;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = "#ffffff";
  ctx.strokeStyle = "#ccc";
  ctx.fill();
  ctx.stroke();
  ctx.closePath();

  // const colHeght = height / 6;
  // const colwidth = colHeght;
  // const colCount = Math.floor(width / colwidth);

  ctx.beginPath();
  ctx.strokeStyle = "#ccc";
  ctx.lineWidth = lineWidth;

  const mCWidth = height / 12.0;

  const mColCount = parseInt(`${width / (mCWidth > 0 ? mCWidth : 0.1)}`);

  for (let i = 0; i <= mColCount; i++) {
    let pointX = i * mCWidth;
    if (i == 0) {
      pointX = pointX + lineWidth / 2;
    }

    if (i < beadColCounts * 2) {
      if (i % 2 == 0) {
        ctx.moveTo(pointX, 0);
        ctx.lineTo(pointX, height);
      }
    } else {
      ctx.moveTo(pointX, 0);
      ctx.lineTo(pointX, height);
    }

    if (i <= 12) {
      let pointY = i * mCWidth;
      if (i == 0) {
        pointY = pointY + lineWidth / 2;
      }

      if (i % 2 == 0) {
        ctx.moveTo(0, pointY);
        ctx.lineTo(width, pointY);
      } else {
        ctx.moveTo(beadColCounts * 2 * mCWidth, pointY);
        ctx.lineTo(width, pointY);
      }
    }
  }

  // draw bottom line
  ctx.moveTo(0, height - lineWidth);
  ctx.lineTo(width, height - lineWidth);

  // draw right edge line
  ctx.moveTo(width - lineWidth, 0);
  ctx.lineTo(width - lineWidth, height);
  ctx.stroke();
  ctx.closePath();

  return new RoadMapProperties(ctx, width, height, mCWidth, dpi);
}

export function drawRoadMapGrid1(
  canvas: HTMLCanvasElement
): RoadMapProperties | undefined {
  const { ctx, dpi, width, height } = fixCanvasSize(canvas);
  const lineWidth = 1 * dpi;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = "#ffffff";
  ctx.strokeStyle = "#ccc";
  ctx.fill();
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.strokeStyle = "#ccc";
  ctx.lineWidth = lineWidth;

  const mCWidth = height / 12;
  const mColNo = width / mCWidth;

  if (height > 0 && width > 0) {
    for (let i = 0; i <= mColNo; i++) {
      const pointX = i * mCWidth;
      ctx.moveTo(pointX, 0);
      ctx.lineTo(pointX, height);
      if (pointX < height) {
        const pointY = pointX;
        ctx.moveTo(0, pointY);
        ctx.lineTo(width, pointY);
      }
    }
  }

  ctx.stroke();
  ctx.closePath();
  return new RoadMapProperties(ctx, width, height, mCWidth * 2, dpi);
}

export function drawRoadMapGrid2(
  canvas: HTMLCanvasElement
): RoadMapProperties | undefined {
  const { ctx, dpi, width, height } = fixCanvasSize(canvas);

  const lineWidth = 1 * dpi;
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fillStyle = "#ffffff";
  ctx.strokeStyle = "#0000008e";
  ctx.fill();
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.strokeStyle = "#0000008e";
  ctx.lineWidth = lineWidth;

  const mCWidth = height / 12;
  const mColNo = width / mCWidth;

  for (let i = 0; i <= mColNo; i++) {
    const pointX = i * mCWidth;
    ctx.moveTo(pointX, 0);
    ctx.lineTo(pointX, height);
    ctx.moveTo(pointX + mCWidth / 2, mCWidth * 6);
    ctx.lineTo(pointX + mCWidth / 2, height);

    if (pointX < height) {
      const pointY = pointX;
      ctx.moveTo(0, pointY);
      ctx.lineTo(width, pointY);
      if (i >= 6) {
        ctx.moveTo(0, pointY + mCWidth / 2);
        ctx.lineTo(width, pointY + mCWidth / 2);
      }
    }
  }
  ctx.stroke();
  ctx.closePath();

  return new RoadMapProperties(ctx, width, height, mCWidth, dpi);
}

/**
 * Draw Grid Single
 * @param canvas
 */
export function drawSingleGridMap(
  canvas: HTMLCanvasElement,
  colors?: { stroke?: string; fill?: string }
): RoadMapProperties | undefined {
  const { ctx, dpi, width, height } = fixCanvasSize(canvas);

  if (!ctx) {
    return undefined;
  }

  const border = 1 * dpi;
  // border
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  if (colors && colors.fill) {
    ctx.fillStyle = colors.fill;
  } else {
    ctx.fillStyle = "#ccc";
  }
  ctx.fill();
  ctx.closePath();

  // background
  ctx.beginPath();
  ctx.rect(border, border, width - border * 2, height - border * 2);

  if (colors && colors.fill) {
    ctx.fillStyle = colors.fill;
  } else {
    ctx.fillStyle = "#fff";
  }
  ctx.fill();
  ctx.closePath();

  const lineWidth = 0.5 * dpi;

  ctx.beginPath();

  if (colors && colors.stroke) {
    ctx.strokeStyle = colors.stroke;
  } else {
    ctx.strokeStyle = "#999999";
  }

  ctx.lineWidth = lineWidth * dpi;

  const mCWidth = height / 6;

  const mColCount = parseInt(`${width / (mCWidth > 0 ? mCWidth : 0.1)}`);

  for (let i = 0; i <= mColCount; i++) {
    let pointX = i * mCWidth;
    if (i === 0) {
      pointX = pointX + lineWidth / 2;
    }
    ctx.moveTo(pointX, 0);
    ctx.lineTo(pointX, height);
    if (i <= 6) {
      let pointY = i * mCWidth;
      if (i === 0) {
        pointY = pointY + lineWidth / 2;
      }
      ctx.moveTo(0, pointY);
      ctx.lineTo(width, pointY);
    }
  }
  ctx.stroke();
  ctx.closePath();

  return new RoadMapProperties(ctx, width, height, mCWidth, dpi);
}
