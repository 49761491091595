String.prototype.encodeToB64 = function (this: string) {
  return btoa(unescape(encodeURIComponent(this)));
};

String.prototype.decodeFromB64 = function (this: string): string {
  return decodeURIComponent(escape(atob(this)));
};

String.prototype.toObject = function <T>(this: string) {
  return JSON.parse(this) as T;
};
