import axios from "axios";
import { store } from "@/store";
import { getCookieValue } from ".";
import CookiesToken from "@/types/CookiesToken";

// axios.defaults.adapter = require('axios/lib/adapters/http')
// create service
const service = axios.create({
  baseURL: `${process.env.API_URL_LINE1}/${process.env.API_EXT}`,
  timeout: 5000,
});

// // request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.responseType = 'arraybuffer'
    // config.responseType = 'arraybuffer'

    const line = parseInt(getCookieValue(CookiesToken.LINE) ?? "0");

    if (line > 0) {
      if (line === 1) {
        config.baseURL = `${process.env.API_URL_LINE1}/${process.env.API_EXT}`;
      } else if (line === 2) {
        config.baseURL = `${process.env.API_URL_LINE2}/${process.env.API_EXT}`;
      } else if (line === 3) {
        config.baseURL = `${process.env.API_URL_LINE3}/${process.env.API_EXT}`;
      }
    } else if (line === -1) {
      const customIp = store.getters.serverLineStr as string;
      config.baseURL = `${customIp}/${process.env.API_EXT}`;
    }

    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// request response interceptor
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(`error: ${error}`); // debug error
    return Promise.reject(error);
  }
);

export default service;
