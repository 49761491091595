enum AppMutation {
  SET_FULL_SCREEN = "SET_FULL_SCREEN",
  SET_SOUND_STATE = "SET_SOUND_STATE",
  SET_VIDEO_STATE = "SET_VIDEO_STATE",
  SET_USER_INTERACTION = "SET_USER_INTERACTION",
  SHOW_LOADING = "SHOW_LOADING",
  HIDE_LOADING = "HIDE_LOADING",
  APP_SET_LINE = "APP_SET_LINE",
  APP_SET_LINE_STR = "APP_SET_LINE_STR",
  SET_NOTICE = "SET_NOTICE",
  SET_TOKEN = "SET_TOKEN",
  SET_PERSONAL_LIMIT = "SET_PERSONAL_LIMIT",
  SET_COIN = "SET_COIN",
  SET_USER = "SET_USER",
  CLEAR_ALL = "CLEAR_ALL",
  SET_CURRENT_LIMIT_INFO = "SET_CURRENT_LIMIT_INFO",
  SET_CHIPS = "SET_CHIPS",
  SET_GAME_OPTION = "SET_GAME_OPTION",
  SET_CURRENT_DESK = "SET_CURRENT_DESK",
  ADD_RESULT_TO_CACHE = "ADD_RESULT_TO_CACHE",
  SET_ORIENTATION = "SET_ORIENTATION",
  SET_CUSTOM_CHIP = "SET_CUSTOM_CHIP",
  HIDE_CUSTOM_CHIP_TOOL_TIP = "HIDE_CUSTOM_CHIP_TOOL_TIP",
  SET_LANG = "SET_LANG",
  INIT_CUSTOM_TABLES = "INIT_CUSTOM_TABLES",
}

enum AppActions {
  PING_SERVER = "pingServer",
  USER_LOGIN = "userLogin",
  GET_DESKS = "getDesks",
  GET_DESK = "getDesk",
  NOTICE_AND_LIMIT_INFO = "getNoticeAndLimitInfo",
  PLACE_DT_BET = "placeDragonTigerBet",
  GET_CARD_INFO = "getCardInfo",
  LOG_OUT = "logOutUser",
  GET_BET_LOG = "getBetLog",
  REQUEST_TO_API = "requestToApi",
}

export { AppMutation, AppActions };
