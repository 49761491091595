
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useScaleUtils } from "@/composables/scaleUtil";
import { useStore } from "./store";
import { getDeviceOrientation } from "./utils";
import screenfull from "screenfull";
import Loading from "@/components/loading.vue";
import BussEventType from "./types/BussEventType";
import {
  DialogBoxName,
  MessageBoxActionType,
  MessageBoxEvent,
} from "./types/MessageBox";
import LimitMsgBox from "@/components/message-box/limit.msg-box.vue";
import MsgBoxPass from "@/components/message-box/passwor-msg-box.vue";
import HistoryMsgBox from "@/components/message-box/history-msg-box.vue";
import { AppMutation } from "./store/types";
import audioPlayer from "./utils/sounds";
import { useRoute } from "vue-router";
import { ROUTES } from "./router";
import { NumericVirtualKeyboard, LanguageMessageBox } from "@/components";

export default defineComponent({
  name: "app",
  components: {
    loading: Loading,
    "msg-box-history": HistoryMsgBox,
    "msg-box-pw": MsgBoxPass,
    "msg-box-limit": LimitMsgBox,
    "numeric-keyboard": NumericVirtualKeyboard,
    "msg-box-language": LanguageMessageBox,
  },
  setup() {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const route = useRoute();
    const { setScaleSize, setMobilScale } = useScaleUtils();
    const domeScale = ref<number>(1);
    const mainEl = ref<HTMLElement>();
    const messageCounter = ref<number>(0);
    const toastMessage = ref<string>("");
    const messageBoxArr = ref<string[]>([]);
    const showHistoryMsgBox = ref<boolean>(false);
    const showPwMsgBox = ref<boolean>(false);
    const showLimitMsgBox = ref<boolean>(false);
    let toastInterval: number | boolean = false;
    const appKey = ref<number>(new Date().getTime());
    const showNumKey = ref<boolean>(false);
    const showLangMsgBox = ref<boolean>(false);

    // let screenWidth = 0;
    // let screenHeight = 0;

    const eventsToListen = [
      "pageshow",
      "focus",
      "blur",
      "visibilitychange",
      "resume",
    ];

    const showView = ref<boolean>(false);
    const mobileOrientation = ref<"portrait" | "landscape">(
      getDeviceOrientation()
    );

    const useScale = () => {
      if (isMobile.value) {
        // domeScale.value = setMobilScale();
        // document.body.style.height = `${document.documentElement.clientHeight}px`;
        // document.body.style.width = `${document.documentElement.clientWidth}px`;
        domeScale.value = 1;

        // if (mainEl.value) {
        //   // bodyEl.style.setProperty("min-width", "10px");
        //   // bodyEl.style.setProperty(" min-height", "2px");
        //   // console.log(mainEl.value);
        //   // bodyEl.style.minHeight = "10px";
        //   // bodyEl.style.minWidth = "10px";
        //   mainEl.value.style.height = window.innerHeight + "px !important";
        //   mainEl.value.style.width = window.innerWidth + "px !important";
        //   mainEl.value.style.overflow = "auto";
        // }

        // const bodyEl = document.getElementsByTagName("body")[0];

        // if (bodyEl) {
        //   bodyEl.style.minHeight = `${
        //     mobileOrientation.value === "portrait" ? screenWidth : screenHeight
        //   }px`;
        //   bodyEl.style.minWidth = `${
        //     mobileOrientation.value === "portrait" ? screenHeight : screenWidth
        //   }px`;
        // }
        // handleOrientationChange();
        // alert(mobileOrientation.value);
      } else {
        domeScale.value = setScaleSize();
      }
      emitter.emit(BussEventType.WINDOW_RESIZED);

      if (screenfull.isEnabled) {
        store.commit(AppMutation.SET_FULL_SCREEN, screenfull.isFullscreen);
      }
    };

    const pageStateChange = (nextState: "hidden" | "active" | "passive") => {
      const prevState = pageState;
      if (nextState !== prevState) {
        // this.events.push(nextState);
        pageState = nextState;

        if (
          (prevState === "hidden" || prevState === "passive") &&
          pageState === "active"
        ) {
          // do something when active
          // set orientation if its a mobile
          mobileOrientation.value = getDeviceOrientation();
          appKey.value = new Date().getTime();
        } else if (
          prevState === "active" &&
          (pageState === "hidden" || pageState === "passive")
        ) {
          // do something when lost focus
          // set set orientation
          mobileOrientation.value = getDeviceOrientation();
          appKey.value = new Date().getTime();
        }
      }
    };

    const getPageState = (): "hidden" | "active" | "passive" => {
      if (document.visibilityState === "hidden") {
        return "hidden";
      }

      if (document.hasFocus()) {
        return "active";
      }
      return "passive";
    };
    let pageState = getPageState();

    const initSound = () => {
      if (userInteraction.value !== 1) {
        store.commit(AppMutation.SET_USER_INTERACTION);
        audioPlayer.Start();
      }
    };

    const handleOrientationChange = () => {
      mobileOrientation.value = getDeviceOrientation();

      store.commit(AppMutation.SET_ORIENTATION, mobileOrientation.value);

      if (
        mobileOrientation.value === "landscape" &&
        screenfull.isEnabled &&
        !screenfull.isFullscreen
      ) {
        const docEl = document.getElementById("app") as HTMLElement;

        screenfull
          .request(docEl, { navigationUI: "hide" })
          .then(() => {
            //
          })
          .catch((e) => {
            //
          });
      }
    };

    const handleToastMessage = (message: string) => {
      messageCounter.value = 9;
      toastMessage.value = message;
      if (typeof toastInterval !== "number") {
        toastInterval = setInterval(() => {
          messageCounter.value -= 1;
          if (messageCounter.value <= 0 && typeof toastInterval === "number") {
            clearInterval(toastInterval);
            toastInterval = false;
            toastMessage.value = "";
          }
        }, 500);
      }
    };

    const requestFullScreen = () => {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          screenfull.exit().then(() => {
            store.commit(AppMutation.SET_FULL_SCREEN, screenfull.isFullscreen);
          });
        } else {
          const docEl = document.getElementById("app") as HTMLElement;
          screenfull.request(docEl, { navigationUI: "hide" }).then(() => {
            store.commit(AppMutation.SET_FULL_SCREEN, screenfull.isFullscreen);
          });
        }
      }
    };

    const handleShowDialogBox = (event: MessageBoxEvent) => {
      if (event.type === MessageBoxActionType.close) {
        if (messageBoxArr.value.includes(event.name)) {
          const _index = messageBoxArr.value.indexOf(event.name);

          messageBoxArr.value.splice(_index, 1);
        }

        if (event.name === "record") showHistoryMsgBox.value = false;
        else if (event.name === "security") showPwMsgBox.value = false;
        else if (event.name === "limit") showLimitMsgBox.value = false;
        else if (event.name === "lang") showLangMsgBox.value = false;
      } else if (event.type === MessageBoxActionType.open) {
        if (!messageBoxArr.value.includes(event.name))
          messageBoxArr.value.push(event.name);

        if (event.name === "record") showHistoryMsgBox.value = true;
        else if (event.name === "security") showPwMsgBox.value = true;
        else if (event.name === "limit") showLimitMsgBox.value = true;
        else if (event.name === "lang") showLangMsgBox.value = true;
      }
    };

    const closeAllDialogs = () => {
      // hide num key
      showNumKey.value = false;
      const _msgboxArr = [...messageBoxArr.value];

      _msgboxArr.forEach((_msgBoxName) => {
        const evt: MessageBoxEvent = {
          type: MessageBoxActionType.close,
          name: _msgBoxName as DialogBoxName,
        };
        handleShowDialogBox(evt);
      });
      //746 x 428

      messageBoxArr.value = new Array<string>();
    };

    const showOrHideNumKeyboard = (_isShow: boolean) => {
      showNumKey.value = _isShow;
    };

    const handleCloseNumKey = () => {
      showNumKey.value = false;
    };

    const handleConfirmNumKey = (_numKeyValue: number) => {
      store.commit(AppMutation.SET_CUSTOM_CHIP, _numKeyValue);
      showNumKey.value = false;
    };

    onMounted(() => {
      // alert(`h${window.innerHeight} : w${window.innerWidth} `);
      let domEl = document.querySelector("#app");
      if (isMobile.value) {
        emitter.on(
          BussEventType.SHOW_OR_HIDE_NUM_KEYBOARD,
          showOrHideNumKeyboard
        );
        domEl && (domEl.className = "mobile");

        window.addEventListener("orientationchange", handleOrientationChange);
        // initialize the orientation
        handleOrientationChange();
        const body = document.getElementsByTagName("body")[0];

        // const _screenWidth =
        //   window.innerWidth ||
        //   document.documentElement.clientWidth ||
        //   body.clientWidth;
        // const _screenHeight =
        //   window.innerHeight ||
        //   document.documentElement.clientHeight ||
        //   body.clientHeight;
        // if (mobileOrientation.value === "portrait") {
        //   screenWidth = _screenHeight;
        //   screenHeight = _screenWidth;
        // } else {
        //   screenWidth = _screenWidth;
        //   screenHeight = _screenHeight;
        // }
      } else {
        domEl && (domEl.className = "pc");
      }
      useScale();

      window.addEventListener("resize", useScale);
      emitter.on(BussEventType.TOAST_MESSAGE, handleToastMessage);
      emitter.on(BussEventType.TOGLE_FULL_SCREEN, requestFullScreen);
      emitter.on(BussEventType.DOALOG, handleShowDialogBox);

      if (!audioPlayer.isLoaded) {
        audioPlayer.isOn = isSoundOn.value;
        audioPlayer.LoadSounds();
      }

      if (isMobile.value) {
        eventsToListen.forEach((type) => {
          window.addEventListener(type, () => pageStateChange(getPageState()), {
            capture: true,
          });
        });
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", useScale);
      if (isMobile.value) {
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
      }
      emitter.off(BussEventType.TOAST_MESSAGE, handleToastMessage);
      emitter.off(BussEventType.TOGLE_FULL_SCREEN, requestFullScreen);
      emitter.off(BussEventType.DOALOG, handleShowDialogBox);

      if (isMobile.value) {
        emitter.off(
          BussEventType.SHOW_OR_HIDE_NUM_KEYBOARD,
          showOrHideNumKeyboard
        );
        eventsToListen.forEach((type) => {
          window.removeEventListener(
            type,
            () => pageStateChange(getPageState()),
            {
              capture: true,
            }
          );
        });
      }
    });

    const isMobile = computed((): boolean => store.getters["isMobile"]);
    const isSoundOn = computed((): number => store.getters["audioIsOn"]);
    const isLoading = computed(() => store.getters["isLoading"]);
    const showDimOverlay = computed(() => messageBoxArr.value.length > 0);
    const userInteraction = computed(
      (): number => store.getters["userInteraction"]
    );

    watch(
      () => mobileOrientation.value,
      (_value) => {
        if (!isMobile.value) {
          showView.value = true;
          return;
        } else if (isMobile.value && _value === "landscape") {
          showView.value = true;
          return;
        }

        showView.value = false;
      },
      { immediate: true }
    );

    watch(
      () => route.name,
      (_routeName) => {
        if (typeof _routeName === "string") {
          if (
            _routeName === ROUTES.SERVER_SELECTION ||
            _routeName === ROUTES.LOGIN
          ) {
            closeAllDialogs();
          }
        }
      }
    );

    return {
      domeScale,
      showView,
      messageCounter,
      toastMessage,
      isLoading,
      showDimOverlay,
      showPwMsgBox,
      showLimitMsgBox,
      showHistoryMsgBox,
      appKey,
      mainEl,
      showNumKey,
      showLangMsgBox,
      handleConfirmNumKey,
      handleCloseNumKey,
      initSound,
    };
  },
});
