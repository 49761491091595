interface MIDictionary<T> {
  Add(key: string, value: T): void;
  ContainsKey(key: string): boolean;
  Count(): number;
  Item(key: string): T;
  Keys(): string[];
  Remove(key: string): T;
  Values(): T[];
}

export class Dictionary<T> implements MIDictionary<T> {
  // Items
  private items: { [index: string]: T } = {};
  // Number of item
  private count = 0;

  private checkOwnProperty(obj: any, propertyName: string): boolean {
    return (
      obj &&
      (typeof obj === "object" || typeof obj === "function") &&
      Object.prototype.hasOwnProperty.call(obj, propertyName)
    );
  }

  public ContainsKey(key: string, gh = true): boolean {
    // return (this.items).hasOwnProperty(key);
    if (!gh) {
      console.log("let");
    }
    return this.checkOwnProperty(this.items, key);
  }

  public Count(): number {
    return this.count;
  }

  public Add(key: string, value: T) {
    if (!this.checkOwnProperty(this.items, key)) this.count++;

    this.items[key] = value;
  }

  public Remove(key: string): T {
    const val = this.items[key];
    delete this.items[key];
    this.count--;
    return val;
  }

  public Item(key: string): T {
    return this.items[key];
  }

  public Keys(): string[] {
    const keySet: string[] = [];

    for (const prop in this.items) {
      if (this.checkOwnProperty(this.items, prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  public Values(): T[] {
    const values: T[] = [];

    for (const prop in this.items) {
      if (this.checkOwnProperty(this.items, prop)) {
        values.push(this.items[prop]);
      }
    }
    return values;
  }
}
