import { LimitInfo } from "vue";

export class Desk {
  tableNo!: number;
  gameSet!: number;
  currentTime!: number;
  isReserved = false;
  limitInfo: LimitInfo = {};
  ju!: number;
  gameType!: number;
  gameId!: string;
  bankerBet = 0;
  playerBet = 0;
  dragonBet = 0;
  tigerBet = 0;
  redBet = 0;
  blueBet = 0;
  tieBet = 0;
  redPairBet = 0;
  bluePairBet = 0;
  status!: number;
  name!: string;
  balance!: number;
  notice!: string;
  result!: string;

  /**
   * Parse Single Desk Data
   * @param data
   */
  public static Parse(data: string): Desk {
    const _desk = new Desk();
    _desk.limitInfo = {};
    const _keyValues = data.split("&");

    _keyValues.forEach((item) => {
      if (item.startsWith("mdesk=")) {
        _desk.tableNo = parseInt(item.split("=")[1]);
      } else if (item.startsWith("xue=")) {
        _desk.gameSet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("xztime=")) {
        _desk.currentTime = parseInt(item.split("=")[1]);
      } else if (item.startsWith("baotai=")) {
        _desk.isReserved = item.split("=")[1] === "1";
      } else if (item.startsWith("downLimit=")) {
        _desk.limitInfo.minBet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("upLimit=")) {
        _desk.limitInfo.maxBet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("ju=")) {
        _desk.ju = parseInt(item.split("=")[1]);
      } else if (item.startsWith("gameType=")) {
        _desk.gameType = parseInt(item.split("=")[1]);
      } else if (item.startsWith("GameID=")) {
        _desk.gameId = String(item.split("=")[1]);
      } else if (item.startsWith("y_z=")) {
        _desk.redBet = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("y_x=")) {
        _desk.blueBet = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("y_h=")) {
        _desk.tieBet = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("y_zd=")) {
        // red pair
        _desk.redPairBet = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("y_xd=")) {
        // blue pair
        _desk.bluePairBet = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("UserPoint=")) {
        _desk.balance = parseInt(item.split("=")[1]) ?? 0;
      } else if (item.startsWith("gonggao=")) {
        _desk.notice = item.split("=")[1];
      } else if (item.startsWith("gameStatus=")) {
        _desk.status = parseInt(item.split("=")[1]);
      } else if (item.startsWith("result=")) {
        const _itemTemp = item.split("=");
        if (_itemTemp.length > 1) {
          _desk.result = _itemTemp[1];
        } else {
          _desk.result = "";
        }
      } else if (item.startsWith("ShowName=")) {
        _desk.name = item.split("=")[1];
      } else if (item.startsWith("tieDonwLimit=")) {
        _desk.limitInfo.tieMinBet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("tieUpLimit=")) {
        _desk.limitInfo.tieMaxBet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("pairDonwLimit=")) {
        _desk.limitInfo.pairMinBet = parseInt(item.split("=")[1]);
      } else if (item.startsWith("pairUpLimit=")) {
        _desk.limitInfo.pairMaxBet = parseInt(item.split("=")[1]);
      }
    });

    return _desk;
  }

  /**
   * Parse List Desk Data
   * @param strData
   */
  public static ParseList(strData: string): Desk[] {
    const _result: Desk[] = [];

    if (strData === "") return _result;

    const _keyValues = strData.split("$");
    _keyValues.pop();

    _keyValues.forEach((item) => {
      _result.push(Desk.Parse(item));
    });

    return _result;
  }
}
