
import { defineComponent, PropType } from "vue";
import { Desk } from "@/types/Desk";
import LobyListItemV2 from "@/components/loby-list-item-v2.vue";

export default defineComponent({
  name: "loby-list",
  props: {
    desks: {
      required: true,
      type: Array as PropType<Desk[]>,
    },
    gType: {
      required: true,
      type: Number as PropType<number>,
    },
    selectedId: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  components: {
    "loby-list-item-v2": LobyListItemV2,
  },
  setup(props, context) {
    const onCLicked = (deskId: number) => {
      context.emit("on-selected", deskId);
    };

    const tableN0 = (desk: Desk) => desk.tableNo;

    return {
      tableN0,
      onCLicked,
    };
  },
});
