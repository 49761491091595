
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  LimitInfo,
  onBeforeUnmount,
  PropType,
  ref,
  watch,
} from "vue";
import RoadMap from "@/components/roadmap/index.vue";
import { Desk } from "@/types/Desk";
import { RoadMapData } from "./roadmap/datamodel/RoadMapData";
import { useStore } from "@/store";
import router from "@/router";
import { NoticeAndLimitParams } from "@/types/api-params";
import { AppActions, AppMutation } from "@/store/types";
import BussEventType from "@/types/BussEventType";
import { GAME_TYPE } from "@/types/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "loby-list-item",
  components: {
    "road-map": RoadMap,
  },
  props: {
    desk: {
      required: true,
      type: Desk as PropType<Desk>,
    },
    gameType: {
      required: true,
      type: Number as PropType<number>,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const emitter = inject("emitter") as Emitter;
    const { t } = useI18n();
    const roadMapData = ref<RoadMapData>(new RoadMapData());
    const currentCount = ref<number>(-1);
    let countDownInterval: boolean | number = false;

    const onDataParsed = (rData: RoadMapData) => {
      roadMapData.value = rData;
    };

    const gameTypeLabel = computed(() => {
      if (props.gameType === GAME_TYPE.BACCARAT) return t("bac");
      else if (props.gameType === GAME_TYPE.DRAGON_TIGER) return t("dra");
      else return props.desk.gameType === 1 ? t("bac") : t("dra");
    });

    const lang = computed(() => store.getters["lang"]);

    const OnEnterTable = (limitid: number) => {
      const model = new NoticeAndLimitParams(
        props.desk.tableNo,
        limitid,
        lang.value
      );
      store.commit(AppMutation.SHOW_LOADING);
      store
        .dispatch(AppActions.NOTICE_AND_LIMIT_INFO, model)
        .then((response: string) => {
          store.commit(AppMutation.HIDE_LOADING);
          if (response === "err=2") {
            emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
            store.commit(AppMutation.CLEAR_ALL);
            router.push({ name: "select-server-line" });
          } else {
            router.push({
              path: "/game",
              query: {
                desk: props.desk.tableNo,
                gameType: props.gameType,
                xian: limitid,
              },
            });
          }
        })
        .catch((err) => {
          store.commit(AppMutation.HIDE_LOADING);
        });
    };

    const startCountDown = () => {
      if (typeof countDownInterval !== "number") {
        currentCount.value = props.desk.currentTime;

        countDownInterval = setInterval(() => {
          if (currentCount.value < 0) {
            stopCountDown();
          } else {
            currentCount.value -= 1;
          }
        }, 1000);
      }
    };

    const stopCountDown = () => {
      if (typeof countDownInterval === "number") {
        clearInterval(countDownInterval);
        countDownInterval = false;
        currentCount.value = -1;
      }
    };

    const personalLimit = computed(
      (): LimitInfo[] => store.getters["personalLimit"]
    );

    const deviceOrientation = computed(
      (): "portrait" | "landscape" => store.getters["deviceOrientation"]
    );

    const timerColor = computed((): string => {
      if (currentCount.value > 20) return "green";
      else if (currentCount.value <= 20 && currentCount.value > 10)
        return "orange";
      else if (currentCount.value <= 10 && currentCount.value !== -1)
        return "red";
      return "";
    });

    onBeforeUnmount(() => {
      stopCountDown();
    });

    watch(
      () => props.desk,
      (_value) => {
        if (_value.currentTime !== -1) {
          startCountDown();
        } else {
          stopCountDown();
        }
      },
      { immediate: true }
    );

    return {
      personalLimit,
      gameTypeLabel,
      deviceOrientation,
      onDataParsed,
      OnEnterTable,
      t,
      roadMapData,
      currentCount,
      timerColor,
    };
  },
});
