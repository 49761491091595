enum MessageBoxActionType {
  close = "close",
  open = "open",
}

type MessageBoxEvent = {
  type: MessageBoxActionType;
  message?: string | unknown;
  name: DialogBoxName;
};

type DialogBoxName =
  | "rule"
  | "setting"
  | "security"
  | "chips"
  | "record"
  | "exit"
  | "limit"
  | "lang";

export { MessageBoxActionType, MessageBoxEvent, DialogBoxName };
