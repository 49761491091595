import "@/utils/string-ext";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

import mitt from "mitt";
import Events from "./types/Events";
import VCalendar from "v-calendar";

import "@/assets/styles.css";
import "@/styles/index.scss";

import "@/permission";
import clickOutside from "./directives/click-outside";

const emitter = mitt<Events>();

import i18n from "./i8n";

NodePlayer.debug(false);
NodePlayer.load(() => {
  const app = createApp(App);
  app.directive("click-outside", clickOutside);

  app.provide("emitter", emitter);
  app.use(i18n).use(store, key).use(router).use(VCalendar, {}).mount("#app");
});
