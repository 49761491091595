
import { useStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watch,
} from "vue";

export default defineComponent({
  name: "calendar-picker",
  props: {
    enabled: {
      default: true,
      type: Boolean as PropType<boolean>,
    },
    placeHolder: {
      default: "",
      type: String as PropType<string>,
    },
    value: {
      default: null,
      type: Object as PropType<Date>,
    },
  },
  setup(props, context) {
    const dateC = ref<Date | null>(null);
    const minDate = ref<Date | null>(null);
    const store = useStore();
    const attrs = [
      {
        key: "today",
        highlight: {
          color: "purple",
          fillMode: "outline",
          contentClass: "italic",
        },
        dates: new Date(),
      },
    ];

    //    const   setFocus = ()=> {
    //         const el = this.$refs['input'] as Element
    //         el.dispatchEvent(new Event('mouseover'))
    //     }

    const lang = computed(() => store.getters["lang"]);

    onBeforeMount(() => {
      if (props.value) dateC.value = props.value;

      const _days = 49;
      const _date = new Date();
      const _last = new Date(_date.getTime() - _days * 24 * 60 * 60 * 1000);
      const _day = _last.getDate();
      const _month = _last.getMonth() + 1;
      const _year = _last.getFullYear();

      minDate.value = new Date(`${_month}/${_day}/${_year}`);
    });

    watch(
      () => dateC.value,
      (value) => {
        if (value) context.emit("on-change", value);
      },
      { immediate: false }
    );

    return {
      dateC,
      attrs,
      minDate,
      lang,
    };
  },
});
