
import { useStore } from "@/store";
import { Desk } from "@/types/Desk";
import {
  defineComponent,
  PropType,
  computed,
  LimitInfo,
  popScopeId,
  ref,
  watch,
} from "vue";
import RoadMap from "@/components/roadmap/index.vue";
import { GAME_TYPE } from "@/types/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    "road-map": RoadMap,
  },
  props: {
    itemData: {
      required: true,
      type: Object as PropType<Desk>,
    },
    gameLabel: {
      required: true,
      type: Number as PropType<number>,
    },
    selectedId: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  setup(props, context) {
    const store = useStore();
    const { t } = useI18n();
    const currentCount = ref<number>(-1);
    let countDownInterval: boolean | number = false;

    const limitMaxCount = [0, 1, 2];

    const OnTableLimitClicked = (_index: number) => {
      if (personalLimit.value.length >= _index + 1) {
        // enter to this table
        const limitid = personalLimit.value[_index].index || -1;
        if (limitid === -1) return;
        context.emit("enter-table", props.itemData, limitid);
      } else {
        return;
      }
    };

    const minBet = (_index: number) => {
      if (personalLimit.value.length >= _index + 1) {
        return personalLimit.value[_index].minBet || 0;
      }
      return 0;
    };

    const maxBet = (_index: number) => {
      if (personalLimit.value.length >= _index + 1) {
        return personalLimit.value[_index].maxBet || 0;
      }
      return 0;
    };

    const startCountDown = () => {
      if (typeof countDownInterval !== "number") {
        currentCount.value = props.itemData.currentTime;

        countDownInterval = setInterval(() => {
          if (currentCount.value < 0) {
            stopCountDown();
          } else {
            currentCount.value -= 1;
          }
        }, 1000);
      }
    };
    const stopCountDown = () => {
      if (typeof countDownInterval === "number") {
        clearInterval(countDownInterval);
        countDownInterval = false;
        currentCount.value = -1;
      }
    };

    const personalLimit = computed(
      (): LimitInfo[] => store.getters["personalLimit"]
    );

    const gameTypeLabel = computed((): string => {
      if (props.gameLabel === GAME_TYPE.BACCARAT) return t("bac");
      else if (props.gameLabel === GAME_TYPE.DRAGON_TIGER) return t("dra");
      else return props.itemData.gameType === 1 ? t("bac") : t("dra");
    });

    const tableNo = computed(() => {
      return props.itemData.tableNo;
    });

    const result = computed(() => {
      return props.itemData.result;
    });

    const gameType = computed(() => {
      return props.itemData.gameType;
    });

    const timerColor = computed((): string => {
      if (currentCount.value > 20) return "green";
      else if (currentCount.value <= 20 && currentCount.value > 10)
        return "orange";
      else if (currentCount.value <= 10 && currentCount.value !== -1)
        return "red";
      return "";
    });

    watch(
      () => props.itemData,
      (_value) => {
        if (_value.currentTime !== -1) {
          startCountDown();
        } else {
          stopCountDown();
        }
      },
      { immediate: true }
    );

    return {
      minBet,
      maxBet,
      OnTableLimitClicked,
      t,
      gameTypeLabel,
      tableNo,
      result,
      gameType,
      limitMaxCount,
      currentCount,
      timerColor,
    };
  },
});
