import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Loby from "@/views/loby.vue";

export enum ROUTES {
  LOBY = "loby",
  SERVER_SELECTION = "select-server-line",
  LOGIN = "login",
  DESK = "game",
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: ROUTES.LOBY,
    component: Loby,
  },
  {
    path: "/select-server-line",
    name: ROUTES.SERVER_SELECTION,
    component: () => import("@/views/server-selection.vue"),
  },
  {
    path: "/login",
    name: ROUTES.LOGIN,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/game",
    name: ROUTES.DESK,
    props: (route) => ({
      desk: parseInt(String(route.query.desk ?? -1)),
      gameType: parseInt(String(route.query.gameType ?? -1)),
      xian: parseInt(String(route.query.xian ?? -1)),
    }),
    component: () => import("@/views/desk.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
