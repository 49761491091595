import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49e26414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-msg-box-root dialog-box" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t("dialog.language")), 1),
      _createElementVNode("span", {
        class: "close icon-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lang, (i, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["btn-lang-item", { active: _ctx.currentLang === i }]),
          key: index,
          onClick: ($event: any) => (_ctx.setLang(i))
        }, _toDisplayString(_ctx.t(`lang.${i}`)), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}