import router from "./router";
import { store } from "./store";
// import { store } from '@/store'

const whitelist = ["/login", "/select-server-line"];

router.beforeEach(async (to, from, next) => {
  const token = store.getters["token"];
  const user = store.getters["user"];

  const isLogin = token && user;

  if (isLogin) {
    if (to.path === whitelist[0] || to.path === whitelist[1]) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (whitelist.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/select-server-line");
    }
  }
});
