
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onMounted,
  ref,
} from "vue";
import CalendarPicker from "@/components/calendar-picker.vue";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import BussEventType from "@/types/BussEventType";
import { BetLog } from "@/types/BetLog";
import { BetLogDeatails } from "@/types/BetLogDetails";
import { useStore } from "@/store";
import { AppActions } from "@/store/types";
import { BetLogDetailsParams, BetLogParams } from "@/types/api-params";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "history-msg-box",
  components: {
    "calendar-picker": CalendarPicker,
  },
  setup() {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const { t } = useI18n();
    const startDate = ref<Date>(new Date());
    const endDate = ref<Date>(new Date());
    const showDetailHistory = ref<boolean>(false);

    const history = ref<BetLog[]>([]);
    const details = ref<BetLogDeatails[]>([]);

    const OnStartDateChange = (_date: Date) => {
      startDate.value = _date;
    };

    const OnEndDateChange = (_date: any) => {
      endDate.value = _date;
    };

    const close = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.close,
        name: "record",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const loadData = () => {
      const _params = new BetLogParams(
        startDate.value.toISOString().substr(0, 10),
        endDate.value.toISOString().substr(0, 10),
        user.value
      );
      store
        .dispatch(AppActions.GET_BET_LOG, _params)
        .then((response: string) => {
          history.value = BetLog.ParseList(response);
        })
        .catch((err) => {
          emitter.emit(BussEventType.TOAST_MESSAGE, err);
        });
    };

    const viewDetails = (_dateStr: string) => {
      details.value = [];
      showDetailHistory.value = true;
      const _params = new BetLogDetailsParams(_dateStr, user.value);
      store
        .dispatch(AppActions.REQUEST_TO_API, _params)
        .then((response: string) => {
          details.value = BetLogDeatails.ParseList(response);
        })
        .catch((err) => emitter.emit(BussEventType.TOAST_MESSAGE, err));
    };

    const roundIfNeeded = (_number: number) => {
      return +_number.toFixed(2);
    };

    //#region Mounted
    const user = computed(() => store.getters["user"]);
    //#endregion

    onMounted(() => {
      loadData();
      //alert("Show Now");
    });

    return {
      startDate,
      endDate,
      history,
      details,
      showDetailHistory,
      t,
      roundIfNeeded,
      OnStartDateChange,
      OnEndDateChange,
      close,
      loadData,
      viewDetails,
    };
  },
});
