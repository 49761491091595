import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42904ba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password-msg-box-root dialog-box" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "feild" }
const _hoisted_6 = { class: "feild" }
const _hoisted_7 = { class: "feild" }
const _hoisted_8 = { class: "error" }
const _hoisted_9 = { class: "btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t("changepwd")), 1),
      _createElementVNode("span", {
        class: "close icon-close",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t("oldpwd")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oldPass) = $event))
          }, null, 512), [
            [_vModelText, _ctx.oldPass]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t("newpwd")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPass) = $event))
          }, null, 512), [
            [_vModelText, _ctx.newPass]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t("confirmnewpwd")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.confirmPass) = $event))
          }, null, 512), [
            [_vModelText, _ctx.confirmPass]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          class: "btn-confirm",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.confirmChangePassword && _ctx.confirmChangePassword(...args)))
        }, _toDisplayString(_ctx.t("confirm")), 1),
        _createElementVNode("div", {
          class: "btn-cancel",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, _toDisplayString(_ctx.t("cancel")), 1)
      ])
    ])
  ]))
}