
import {
  computed,
  CurrentDesk,
  defineComponent,
  Emitter,
  inject,
  LimitInfo,
} from "vue";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import BussEventType from "@/types/BussEventType";
import { useStore } from "@/store";
import { GAME_TYPE } from "@/types/types";

export default defineComponent({
  setup() {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const close = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.close,
        name: "limit",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const limit = computed((): LimitInfo => store.getters["limitInfo"]);

    const pairMax = computed(() => limit.value.pairMaxBet || 0);
    const tieMax = computed(() => limit.value.tieMaxBet || 0);
    const maxBet = computed(() => limit.value.maxBet || 0);
    const minBet = computed(() => limit.value.minBet || 0);

    const isBaccarat = computed(
      () =>
        (store.getters["currentDesk"] as CurrentDesk).gameType !==
        GAME_TYPE.DRAGON_TIGER
    );

    return {
      pairMax,
      tieMax,
      maxBet,
      minBet,
      isBaccarat,
      close,
    };
  },
});
