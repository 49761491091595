enum CookiesToken {
  TOKEN = "aa880",
  COIN = "aa881",
  USER = "aa882",
  MEMBER = "aa883",
  FREE_MEMBER = "aa884",
  GUNNER = "aa885",
  LOBY_NOTICE = "aa886",
  DEFAULT_CHIP = "aa887",
  SOUND_STATE = "aa888",
  VIDEO_STATE = "aa889",
  LINE = "aa8810",
  LINE_STR = "aa8811",
  NOTICE = "aa8812",
  PERSONAL_LIMIT = "aa8813",
  CURRENT_DESK = "aa8814",
  CHIPS = "aa8815",
  CURRENT_LIMIT_INFO = "aa8816",
  GAME_OPTION = "aa8817",
  CHIP_CUSTOM = "aa8818",
  LANG = "aa8819",
  VIP_TABLES = "aa8820",
  BAC_TABLES = "aa8821",
  DT_TABLES = "aa8822",
}

export default CookiesToken;
