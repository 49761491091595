
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: ".btn-default",
  props: {
    label: {
      require: false,
      default: "button",
      type: String as PropType<string>,
    },
  },
  setup() {
    //
  },
});
