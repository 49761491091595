
import { Desk } from "@/types/Desk";
import { GAME_TYPE } from "@/types/types";
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  LimitInfo,
  PropType,
  ref,
} from "vue";
import RoadMap from "@/components/roadmap/index.vue";
import BtnDefault from "@/components/btn-default.vue";
import { useStore } from "@/store";
import { NoticeAndLimitParams } from "@/types/api-params";
import { AppActions, AppMutation } from "@/store/types";
import router from "@/router";
import BussEventType from "@/types/BussEventType";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "loby-list-item-v2",
  components: {
    "road-map": RoadMap,
    "btn-default": BtnDefault,
  },
  props: {
    desk: {
      required: true,
      type: Desk as PropType<Desk>,
    },
    gameType: {
      required: true,
      type: Number as PropType<number>,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const { t } = useI18n();
    const gameTypeLabel = computed(() => {
      if (props.gameType === GAME_TYPE.BACCARAT) return t("bac");
      else if (props.gameType === GAME_TYPE.DRAGON_TIGER) return t("dra");
      else return props.desk.gameType === 1 ? t("bac") : t("dra");
    });

    const getMin = (_index: number) => {
      if (personalLimit.value.length >= _index + 1)
        return personalLimit.value[_index].minBet;

      return 0;
    };

    const getMax = (_index: number) => {
      if (personalLimit.value.length >= _index + 1)
        return personalLimit.value[_index].maxBet;

      return 0;
    };

    const getLimitId = (_index: number): number => {
      if (personalLimit.value.length >= _index + 1)
        return personalLimit.value[_index].index ?? -1;

      return -1;
    };

    const isValidUserInfo = (_index: number): boolean => {
      return personalLimit.value.length >= _index + 1;
    };

    const OnEnterTable = (_index: number) => {
      const limitid = getLimitId(_index);

      if (limitid === -1) return;
      const model = new NoticeAndLimitParams(
        props.desk.tableNo,
        limitid,
        lang.value
      );

      store.commit(AppMutation.SHOW_LOADING);
      store
        .dispatch(AppActions.NOTICE_AND_LIMIT_INFO, model)
        .then((response: string) => {
          store.commit(AppMutation.HIDE_LOADING);
          if (response === "err=2") {
            emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
            store.commit(AppMutation.CLEAR_ALL);
            router.push({ name: "select-server-line" });
          } else {
            router.push({
              path: "/game",
              query: {
                desk: props.desk.tableNo,
                gameType: props.gameType,
                xian: limitid,
              },
            });
          }
        })
        .catch((err) => {
          store.commit(AppMutation.HIDE_LOADING);
        });
    };

    const personalLimit = computed(
      (): LimitInfo[] => store.getters["personalLimit"]
    );

    const lang = computed(() => store.getters["lang"]);

    return {
      t,
      getMin,
      getMax,
      isValidUserInfo,
      gameTypeLabel,
      OnEnterTable,
    };
  },
});
