
// import { useStore } from '@/store'
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RoadMapData } from "./datamodel/RoadMapData";
import { DISPLAY, RoadType } from "./datamodel/types";
import {
  drawRoadMap,
  drawRoadMapGrid,
  drawRoadMapGrid2,
  drawSingleGridMap,
  parseRoadMap,
} from "./utils";

export default defineComponent({
  name: "road-map",
  emits: ["road-map-data"],
  props: {
    mapData: {
      required: true,
      type: String,
    },
    gameType: {
      default: () => 1,
      type: Number,
    },
    enablePrediction: {
      default: () => false,
      type: Boolean,
    },
    beadRowCount: {
      default: () => 13,
      type: Number,
    },
    display: {
      default: () => 0,
      type: Number,
    },
  },
  setup(props, context) {
    const { t } = useI18n();
    // const store = useStore()
    const readyToRender = ref<boolean>(false);
    const mapCanvas = ref<HTMLCanvasElement | unknown>(null);
    const roadMapData = ref<RoadMapData>(new RoadMapData());
    const nextRed = ref<RoadMapData>(new RoadMapData());
    const nextBlue = ref<RoadMapData>(new RoadMapData());
    const predictionTimer = ref<number | boolean>(false);
    const predictionType = ref<number>(0);
    const predictionCount = ref<number>(9);
    const predictionBlink = ref<number>(9);

    const drawMap = () => {
      if (!readyToRender.value) return;

      const display = props.display;

      if (display === DISPLAY.default_horizontal) {
        drawDefaultHorizontalMap(roadMapData.value);
      } else if (display === DISPLAY.default_vertical) {
        drawDefaultVerticalMap(roadMapData.value);
      } else if (display === DISPLAY.bigeye) {
        drawOnlyBigMap(roadMapData.value);
      } else if (display === DISPLAY.no_bead_vertical) {
        //
      } else if (display === DISPLAY.no_bead_horizontal) {
        //
      } else if (display === DISPLAY.bead_only) {
        drawBeadOnly(roadMapData.value);
      }
    };

    const drawDefaultHorizontalMap = (
      mapData: RoadMapData | undefined,
      isPre = false
    ) => {
      const canvas = mapCanvas.value as HTMLCanvasElement;

      const prop = drawRoadMapGrid(canvas, props.beadRowCount);

      if (prop && mapData) {
        prop.isPre = isPre;
        const { beads, bigs, bigeyes, smalls, cockroachs } = mapData;
        const { width } = prop;

        prop.colWidth = prop.height / 6;
        prop.colNum = props.beadRowCount;
        drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, props.gameType, t);

        prop.colNum = Math.floor(
          (width - props.beadRowCount * prop.colWidth) / (prop.colWidth / 2)
        );

        // this will serve as x offset of big big,bigeys, small roadmap
        const offsetX1 = prop.colWidth * props.beadRowCount;
        drawRoadMap(prop, bigs, RoadType.BIG, 0, offsetX1);
        const offsetY1 = prop.colWidth * 3;

        prop.colNum = prop.colNum * 2;
        prop.lineWidth = 2;
        drawRoadMap(prop, bigeyes, RoadType.BIGEYE, offsetY1, offsetX1);

        const offsetY2 = prop.colWidth * 4.5;

        prop.colNum = Math.floor(prop.colNum / 2);
        drawRoadMap(prop, smalls, RoadType.SMALL, offsetY2, offsetX1);
        const offsetX2 = offsetX1 + (prop.colNum * prop.colWidth) / 4;
        drawRoadMap(prop, cockroachs, RoadType.COCKROACH, offsetY2, offsetX2);
      }
    };

    const drawDefaultVerticalMap = (data: RoadMapData, isPre = false) => {
      const canvas = mapCanvas.value as HTMLCanvasElement;
      const prop = drawRoadMapGrid2(canvas);

      if (prop && data) {
        prop.isPre = isPre;
        const { beads, bigs, bigeyes, smalls, cockroachs } = data;
        const { width, colWidth } = prop;

        prop.colNum = Math.floor(width / colWidth);
        drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, props.gameType, t);

        const offsetY1 = colWidth * 6;
        const offsetY2 = offsetY1 + colWidth * 3;
        const offsetY3 = offsetY2 + colWidth * 1.5;

        prop.colNum = Math.floor(width / (colWidth / 2));
        drawRoadMap(prop, bigs, RoadType.BIG, offsetY1, 0);

        prop.colNum = prop.colNum * 2;
        drawRoadMap(prop, bigeyes, RoadType.BIGEYE, offsetY2, 0);

        prop.colNum = Math.floor(prop.colNum / 2);
        drawRoadMap(prop, smalls, RoadType.SMALL, offsetY3, 0);

        const offsetX1 = prop.colNum * (prop.colWidth / 4);
        drawRoadMap(prop, cockroachs, RoadType.COCKROACH, offsetY3, offsetX1);
      }
    };

    const drawOnlyBigMap = (data: RoadMapData, isPre = false) => {
      const canvas = mapCanvas.value as HTMLCanvasElement;
      const prop = drawSingleGridMap(canvas);

      if (prop && data) {
        const { bigs } = data;
        prop.isPre = isPre;
        prop.colWidth = prop.colWidth * 2;
        prop.colNum = Math.floor(prop.width / (prop.colWidth / 2));
        drawRoadMap(prop, bigs, RoadType.BIG, 0, 0);
      }
    };

    const drawBeadOnly = (data: RoadMapData, isPre = false) => {
      const canvas = mapCanvas.value as HTMLCanvasElement;
      const prop = drawSingleGridMap(canvas);

      if (prop && data) {
        const { beads } = data;
        prop.colNum = props.beadRowCount;
        drawRoadMap(prop, beads, RoadType.BEAD, 0, 0, props.gameType, t);
      }
    };

    const Predict = (color: number) => {
      if (!props.enablePrediction) return;

      predictionType.value = color;
      predictionCount.value = predictionBlink.value;

      if (typeof predictionTimer.value !== "number") {
        predictionTimer.value = setInterval(() => {
          // pre
          if (predictionCount.value % 2 !== 0) {
            if (predictionType.value === 1 && nextRed.value) {
              handlePrediction(nextRed.value);
            } else if (predictionType.value === 2 && nextBlue.value) {
              handlePrediction(nextBlue.value);
            }
          } else {
            if (roadMapData.value) {
              if (predictionType.value === 1 && nextRed.value) {
                handlePrediction(nextRed.value, true);
              } else if (predictionType.value === 2 && nextBlue.value) {
                handlePrediction(nextBlue.value, true);
              } else {
                handlePrediction(roadMapData.value);
              }
            }
          }

          if (
            predictionCount.value === 0 &&
            typeof predictionTimer.value === "number"
          ) {
            clearInterval(predictionTimer.value);
            predictionTimer.value = false;
          }

          predictionCount.value--;
        }, 200);
      }
    };

    const handlePrediction = (data: RoadMapData, isPre = false) => {
      if (props.display === DISPLAY.default_horizontal)
        drawDefaultHorizontalMap(data, isPre);
      else if (props.display === DISPLAY.default_vertical) {
        drawDefaultVerticalMap(data, isPre);
      } else if (props.display === DISPLAY.bigeye) {
        drawOnlyBigMap(data, isPre);
      } else if (props.display === DISPLAY.no_bead_vertical) {
        // this.drawNoBeadVertical(data, isPre)
      } else if (props.display === DISPLAY.no_bead_horizontal) {
        // this.drawNoBeadHorizontal(data, isPre)
      }
    };

    onMounted(() => {
      readyToRender.value = true;
      drawMap();
    });

    // const lang = computed((): string => store.getters['lang'])
    //#region Watchers

    watch(
      () => props.mapData,
      (value) => {
        roadMapData.value = parseRoadMap(value);

        nextRed.value = parseRoadMap(`${value}10#`);
        nextBlue.value = parseRoadMap(`${value}30#`);

        if (props.enablePrediction) {
          context.emit(
            "road-map-data",
            roadMapData.value,
            nextRed.value,
            nextBlue.value
          );
        } else {
          context.emit("road-map-data", roadMapData.value);
        }

        if (readyToRender.value && typeof predictionTimer.value !== "number") {
          drawMap();
        }
      },
      { immediate: true }
    );

    watch(
      () => props.display,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          drawMap();
        }
      }
    );

    //#endregion
    return {
      mapCanvas,
      drawMap,
      Predict,
    };
  },
});
