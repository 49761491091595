enum BussEventType {
  TOAST_MESSAGE = "toast_a_message",
  TOGLE_FULL_SCREEN = "togle_full_screen",
  WINDOW_RESIZED = "window_resize",
  DOALOG = "dialog",
  PLACE_BET = "PLACE_BET",
  RESET_BET = "RESET_BET",
  CONFIRM_BET = "CONFIRM_BET",
  SHOW_GAME_RESULT = "SHOW_GAME_RESULT",
  SHOW_OR_HIDE_NUM_KEYBOARD = "SHOW_OR_HIDE_NUM_KEYBOARD",
}

export default BussEventType;
