
import { defineComponent, inject, Emitter, ref, computed } from "vue";
import BussEventType from "@/types/BussEventType";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import { ChangePassParams } from "@/types/api-params";
import { user } from "@/store/module/user";
import { store } from "@/store";
import { AppActions } from "@/store/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const emitter = inject("emitter") as Emitter;
    const { t } = useI18n();
    const errorMessage = ref<string>("");
    const oldPass = ref<string>("");
    const newPass = ref<string>("");
    const confirmPass = ref<string>("");

    const close = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.close,
        name: "security",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const confirmChangePassword = () => {
      if (
        oldPass.value.length === 0 ||
        newPass.value.length === 0 ||
        confirmPass.value.length === 0
      ) {
        errorMessage.value = "必填项目未填";
        return;
      } else if (newPass.value !== confirmPass.value) {
        errorMessage.value = "确认密码输入错误";
        return;
      }

      const _params = new ChangePassParams(
        oldPass.value,
        newPass.value,
        user.value
      );

      store
        .dispatch(AppActions.REQUEST_TO_API, _params)
        .then((response: string) => {
          if (response.includes("err=")) {
            errorMessage.value = "更改密码失败";
          } else {
            errorMessage.value = "";
            emitter.emit(BussEventType.TOAST_MESSAGE, "密码已更改");
            close();
          }
        })
        .catch((e) => emitter.emit(BussEventType.TOAST_MESSAGE, e));
    };

    const user = computed(() => store.getters["user"]);

    return {
      errorMessage,
      oldPass,
      newPass,
      confirmPass,
      t,
      close,
      confirmChangePassword,
    };
  },
});
