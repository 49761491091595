import { getValueOfKeyValue } from "@/utils";

export class BetLogDeatails {
  gameid?: string;
  desk?: number;
  betTime?: string;
  pointed?: number;
  orderpoint?: number;
  jieguo?: string;
  touzhu?: string;

  static Parse(strData: string) {
    const result = new BetLogDeatails();

    const strArr = strData.split("&");

    strArr.forEach((item) => {
      if (item.startsWith("gameid=")) {
        result.gameid = getValueOfKeyValue(item);
      } else if (item.startsWith("desk=")) {
        result.desk = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("betTime=")) {
        result.betTime = getValueOfKeyValue(item);
      } else if (item.startsWith("pointed=")) {
        result.pointed = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("orderpoint=")) {
        result.orderpoint = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("jieguo=")) {
        result.jieguo = getValueOfKeyValue(item);
      } else if (item.startsWith("touzhu")) {
        result.touzhu = getValueOfKeyValue(item);
      }
    });

    return result;
  }

  static ParseList(strData: string): Array<BetLogDeatails> {
    const result = new Array<BetLogDeatails>();

    const parseListFromStrData = strData.split("#");
    parseListFromStrData.shift();
    // parseListFromStrData.shift()
    if (parseListFromStrData.length > 0) {
      for (let i = 0; i < parseListFromStrData.length; i++) {
        const strItem = parseListFromStrData[i];
        const item = BetLogDeatails.Parse(strItem);
        result.push(item);
      }
    }
    return result;
  }
}
