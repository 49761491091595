import { ACT } from "./types";

class BaseUrlParams {
  skey = process.env.APP_SKEY;
  act: number;
  jm = 0;

  constructor(act: number) {
    this.act = act;
  }
}

class LoginParam extends BaseUrlParams {
  username: string;
  pwd: string;

  constructor(act: number, username: string, pwd: string) {
    super(act);
    this.username = username;
    this.pwd = pwd;
  }
}

class MultipleDeskParams extends BaseUrlParams {
  gameType: number;
  sessionID: string;
  username: string;
  constructor(gameType: number, sessionId: string, username: string) {
    super(ACT.MULTIPLE_DESK_INFO);
    this.gameType = gameType;
    this.sessionID = sessionId;
    this.username = username;
  }
}

class NoticeAndLimitParams extends BaseUrlParams {
  desk: number;
  xianId: number;
  lang: string;

  constructor(desk: number, xianId: number, lang: string) {
    super(ACT.GET_NOTICE_AND_LIMIT);
    this.desk = desk;
    this.xianId = xianId;
    this.lang = lang;
  }
}

class SingleDeskParams extends BaseUrlParams {
  desk: number;
  sessionID: string;
  username: string;
  lang: string;

  constructor(desk: number, username: string, sessionID: string, lang: string) {
    super(ACT.SINGLE_DESK_INFO);
    this.desk = desk;
    this.sessionID = sessionID;
    this.username = username;
    this.lang = lang;
  }
}

class DragonTigerBetParams extends BaseUrlParams {
  // dragon
  long = "0";
  // tiger
  hu = "0";
  // tie
  he = "0";
  sessionID: string;
  username: string;
  desk: number;
  xian: number;
  /**
   *
   */
  constructor(desk: number, xian: number, username: string, sessionId: string) {
    super(ACT.DT_BET);

    this.desk = desk;
    this.xian = xian;
    this.username = username;
    this.sessionID = sessionId;
  }
}

class CardInfoParams extends BaseUrlParams {
  gameId: string;
  username: string;

  constructor(username: string, gameId: string) {
    super(ACT.CARD_INFO);
    this.username = username;
    this.gameId = gameId;
  }
}

class LogoutParams extends BaseUrlParams {
  username: string;
  constructor(username: string) {
    super(ACT.LOGOUT);
    this.username = username;
  }
}
class BetLogDetailsParams extends BaseUrlParams {
  username: string;
  sday: string;
  constructor(start: string, username: string) {
    super(ACT.GET_LOG_DETAILS);
    this.sday = start;
    this.username = username;
  }
}

class BetLogParams extends BaseUrlParams {
  username: string;
  sday: string;
  eday: string;
  constructor(start: string, end: string, username: string) {
    super(ACT.GET_LOG);
    this.sday = start;
    this.eday = end;
    this.username = username;
  }
}

class ChangePassParams extends BaseUrlParams {
  opwd: string;
  npwd: string;
  username: string;

  constructor(oldPassword: string, newPassword: string, username: string) {
    super(ACT.CHANGE_PASS);
    this.opwd = oldPassword;
    this.npwd = newPassword;
    this.username = username;
  }
}

class BaccaratBetParams extends BaseUrlParams {
  // banker
  y_z!: number;
  // player
  y_x!: number;
  // banker pair
  y_zd!: number;
  // player pair
  y_xd!: number;
  // tie
  y_h!: number;
  sessionID: string;
  username: string;
  desk: number;
  xian: number;
  /**
   *
   */
  constructor(desk: number, xian: number, username: string, sessionId: string) {
    super(ACT.BACCARAT_BET);

    this.desk = desk;
    this.xian = xian;
    this.username = username;
    this.sessionID = sessionId;
  }
}

export {
  LoginParam,
  MultipleDeskParams,
  NoticeAndLimitParams,
  SingleDeskParams,
  DragonTigerBetParams,
  CardInfoParams,
  LogoutParams,
  BetLogDetailsParams,
  BetLogParams,
  ChangePassParams,
  BaccaratBetParams,
  BaseUrlParams,
};
