
import { computed, defineComponent, DropDownItem, PropType, ref } from 'vue'

export default defineComponent({
    name: 'drop-down',
    props: {
        listData: {
            required: true,
            type: Array as PropType<DropDownItem[]>
        },
        selectedIndex: {
            required: true,
            type: Number
        },
        trimList: {
            required: false,
            default: false,
            type: Boolean as PropType<boolean>
        }
    },
    setup(props, { emit }) {
        const showList = ref<boolean>(false)

        const handleClickOutside = () => {
            showList.value = false
        }

        const handleListItemClick = (value: DropDownItem) => {
            emit('on-selected-change', value)
            showList.value = false
        }

        const label = computed(() => {
            if (!props.listData || props.listData.length === 0) {
                return '没有数据'
            } else {
                const item = props.listData.find(
                    (i) => i.id === props.selectedIndex
                )
                if (item) return item.value
                return '没有数据'
            }
        })

        const filteredData = computed(() => {
            if (props.trimList) {
                const lst = props.listData.filter(
                    (i) => i.id !== props.selectedIndex
                )

                return lst
            }
            return props.listData
        })

        return {
            showList,
            label,
            filteredData,
            // methods
            handleClickOutside,
            handleListItemClick
        }
    }
})
