import { getValueOfKeyValue } from "@/utils";

export class BetLog {
  count?: number;
  sumPointed?: number;
  sumOrderPoint?: number;
  sumxmOrderd?: number;
  selfxmOrderd?: number;
  mday?: string;

  static Parse(strData: string): BetLog {
    const result = new BetLog();

    const strArr = strData.split("&");

    strArr.forEach((item) => {
      if (item.startsWith("count=")) {
        result.count = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("mday=")) {
        result.mday = getValueOfKeyValue(item);
      } else if (item.startsWith("sumPointed=")) {
        result.sumPointed = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("sumOrderPoint=")) {
        result.sumOrderPoint = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("sumxmOrderd=")) {
        result.sumxmOrderd = parseInt(getValueOfKeyValue(item) || "0");
      } else if (item.startsWith("selfxmOrderd=")) {
        result.selfxmOrderd = parseFloat(getValueOfKeyValue(item) || "0");
      }
    });

    return result;
  }

  static ParseList(strData: string): Array<BetLog> {
    const result = new Array<BetLog>();

    const parseListFromStrData = strData.split("#");
    parseListFromStrData.shift();
    parseListFromStrData.shift();
    if (parseListFromStrData.length > 0) {
      for (let i = 0; i < parseListFromStrData.length; i++) {
        const strItem = parseListFromStrData[i];
        const item = BetLog.Parse(strItem);
        result.push(item);
      }
    }
    return result;
  }
}
