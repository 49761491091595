import CookiesToken from "@/types/CookiesToken";
import Cookies from "js-cookie";
import { AppState, InjectionKey, State, UserState } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";

import { app } from "./module/app";
import { user } from "./module/user";
import { AppMutation } from "./types";

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    version: "1.0.1",
    root: true,
  },
  mutations: {
    [AppMutation.CLEAR_ALL]() {
      const appState = app.state as AppState;
      const userState = user.state as UserState;

      userState.token = undefined;
      userState.coin = 0;
      userState.notice = "";
      userState.infoKey = new Date().getTime();
      userState.userId = undefined;
      userState.personalLimit = [];
      userState.chips = [];
      userState.currentDesk = undefined;
      userState.limitInfo = undefined;
      userState.currentDesk = undefined;
      userState.gameOption = 4;
      userState.customChip = 0;
      userState.vipTables = [];
      userState.bcTables = [];
      userState.dtTables = [];

      appState.line = 0;
      appState.lineStr = "";
      appState.videoState = 1;
      appState.soundState = 1;
      window.sessionStorage.clear();
    },
  },
  actions: {},
  modules: {
    app,
    user,
  },
});

// define your own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key);
}
