
import {
  computed,
  defineComponent,
  DropDownItem,
  Emitter,
  inject,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  CurrentDesk,
} from "vue";
import { Desk } from "@/types/Desk";
import LobyList from "@/components/loby-list.vue";
import LobyListV2 from "@/components/loby-list-v2.vue";
import { useStore } from "@/store";
import { AppActions, AppMutation } from "@/store/types";
import { LogoutParams, MultipleDeskParams } from "@/types/api-params";
import { getValueByKey, getValueOfKeyValue } from "@/utils";
import router from "@/router";
import BussEventType from "@/types/BussEventType";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import DropDownCtrl from "@/components/drop-down.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "loby",
  components: {
    "loby-list": LobyList,
    "loby-list-v2": LobyListV2,
    "drop-down-ctrl": DropDownCtrl,
  },
  setup() {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const { t } = useI18n();
    // const tabIndex = ref<number>(4)
    const desks = ref<Desk[]>([]);
    const selectedTableId = ref<number>(-1);
    const listKey = ref<number>(new Date().getTime());
    const dropdownCtrlData = ref<DropDownItem[]>([
      { id: 4, value: "百家乐", other: "" },
      { id: 2, value: "龙虎", other: "" },
      { id: 5, value: "先发牌区", other: "" },
    ]);

    const showMobileMenu = ref<boolean>(false);

    const PULL_INTERVAL = 3000;
    let serviceInterval: number | boolean = false;

    const loadData = (forceReload = false) => {
      if (!forceReload) store.commit(AppMutation.SHOW_LOADING);

      const params = new MultipleDeskParams(
        tabIndex.value,
        token.value,
        user.value
      );

      store
        .dispatch(AppActions.GET_DESKS, params)
        .then((response: string) => {
          if (!forceReload) store.commit(AppMutation.HIDE_LOADING);
          const err = getValueByKey(response, "err");
          if (err) {
            stopService();
            store.commit(AppMutation.CLEAR_ALL);
            router.push({ name: "select-server-line" });
          } else {
            desks.value = Desk.ParseList(response);
            if (response.includes("$UserPoint=")) {
              const _userPointValuePair =
                response.split("$")[desks.value.length];
              const _coin = parseInt(
                getValueOfKeyValue(_userPointValuePair) ?? "0"
              );
              store.commit(AppMutation.SET_COIN, _coin);
            }
            if (!forceReload) startService();
          }
        })
        .catch(() => {
          if (!forceReload) {
            store.commit(AppMutation.HIDE_LOADING);
            setTimeout(() => {
              loadData();
            }, PULL_INTERVAL);
          }
        });
    };

    const startService = () => {
      stopService();
      serviceInterval = setInterval(() => {
        loadData(true);
      }, PULL_INTERVAL);
    };

    const stopService = () => {
      if (typeof serviceInterval === "number") {
        clearInterval(serviceInterval);
        serviceInterval = false;
      }
    };
    const onShowLanguageMsbBox = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "lang",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const changeGameOption = (option: number) => {
      if (option === tabIndex.value) return;

      stopService();

      let _currentDesk: CurrentDesk = {
        desk: -1,
        xian: -1,
        gameType: option,
      };
      if (currentDesk.value) {
        _currentDesk = currentDesk.value;
        _currentDesk.gameType = option;
      }

      store.commit(AppMutation.SET_CURRENT_DESK, _currentDesk);

      desks.value = [];
      listKey.value = new Date().getDate();

      store.commit(AppMutation.SHOW_LOADING);

      setTimeout(() => {
        loadData();
      }, 500);
    };

    const handleOnResizeWindow = () => {
      listKey.value = new Date().getTime();
    };

    const OnSelectedDesk = (deskId: number) => {
      // console.log(deskId)
      if (selectedTableId.value === deskId) {
        selectedTableId.value = -1;
        return;
      }

      selectedTableId.value = deskId;
    };

    const visitCustumerService = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      window.open(process.env.CUSTOMER_SERVICE_URL, "_blank");
    };

    const logOut = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      const params = new LogoutParams(user.value);
      store
        .dispatch(AppActions.LOG_OUT, params)
        .then(() => {
          router.push({ name: "select-server-line" });
        })
        .catch((error) => {
          emitter.emit(BussEventType.TOAST_MESSAGE, error);
        });
    };

    const goToRule = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      window.open(
        "http://n1.bb3336.com/resource/rules/rule_home.html",
        "_blank"
      );
    };

    const handleClickOutsideOtherMenu = () => {
      showMobileMenu.value = false;
    };

    const toggleFullScreen = () => {
      emitter.emit(BussEventType.TOGLE_FULL_SCREEN);
    };

    const viewHistory = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "record",
      };
      emitter.emit(BussEventType.DOALOG, _event);
    };

    const showPwModify = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "security",
      };
      emitter.emit(BussEventType.DOALOG, _event);
    };

    const onGOptionSelectionChange = (_value: DropDownItem) => {
      changeGameOption(_value.id);
    };

    const handleToggleOtherMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
    };

    const token = computed(() => store.getters["token"]);
    const user = computed(() => store.getters["user"]);
    const isFull = computed(() => store.getters["isFull"]);
    const isMobileDisplay = computed((): boolean => store.getters["isMobile"]);
    const coin = computed(() => store.getters["coin"]);
    const currentDesk = computed(
      (): CurrentDesk => store.getters["currentDesk"]
    );
    const tabIndex = computed((): number => {
      if (!currentDesk.value) return 4;
      return currentDesk.value.gameType;
    });

    //#region Vue Hooks
    onMounted(() => {
      loadData();
    });
    onBeforeMount(() => {
      emitter.on(BussEventType.WINDOW_RESIZED, handleOnResizeWindow);
    });
    onUnmounted(() => {
      emitter.off(BussEventType.WINDOW_RESIZED, handleOnResizeWindow);
      stopService();
    });

    //#endregion

    return {
      listKey,
      tabIndex,
      desks,
      isFull,
      user,
      coin,
      t,
      dropdownCtrlData,
      selectedTableId,
      isMobileDisplay,
      showMobileMenu,
      onShowLanguageMsbBox,
      visitCustumerService,
      handleToggleOtherMenu,
      handleClickOutsideOtherMenu,
      changeGameOption,
      OnSelectedDesk,
      toggleFullScreen,
      logOut,
      goToRule,
      viewHistory,
      showPwModify,
      onGOptionSelectionChange,
    };
  },
});
