import { Lang } from "@/types/types";

/**
 * Check if It is A mobile device or not
 * @returns bolean
 */
export function isMobile(): boolean {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
  return false;
}

/**
 * Determin if the device is an IOS device
 * @returns bolean
 */
export function isIosMobile(): boolean {
  const _isIOSMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (_isIOSMobile) return true;

  return false;
}

/**
 * get device orientation
 * @returns 'portrait' | 'landscape'
 */
export function getDeviceOrientation(): "portrait" | "landscape" {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window.screen as any).orientation) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window.screen as any).orientation.type.includes("landscape")
      ? "landscape"
      : "portrait";
  }

  // iOS/safari
  // console.log(Math.abs(+window.orientation))
  return Math.abs(+window.orientation) === 90 ? "landscape" : "portrait";
}

export function isValidIp(ipaddress: string) {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(ipaddress)) {
    return true;
  } else {
    return false;
  }
}

/**
 * generate random number between min and max number
 * @param min smallest posible number
 * @param max maximum pasible number
 */
export function generateRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get Value By key
 * @param responseStr
 * @param key
 * @param spliter
 */
export function getValueByKey(
  responseStr: string,
  key: string,
  spliter = "&"
): string | undefined {
  const keyValues = responseStr.split(spliter);
  let value;

  keyValues.forEach((item: string) => {
    if (item.startsWith(`${key}=`)) {
      const keyValue = item.split("=");
      if (keyValue.length > 1) {
        value = keyValue[1];
      }
    }
  });
  return value;
}
/**
 * Get the Value of KeyValue
 * @param keyValue
 */
export function getValueOfKeyValue(keyValue: string): string | undefined {
  if (keyValue === "") return undefined;
  const value = keyValue.split("=");
  if (value.length > 1) {
    return value[1];
  }
  return undefined;
}

/**
 * convert number into money format 1000 to 1,000
 * @param value
 * @returns
 */

export function toMoneyFormat(value: number): string {
  const amount = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  return amount.replace(".00", "");
}

/**
 * return browser language
 * @returns browser language
 */
export function getBrowserLanguage(): string {
  const lang = (navigator.language || "zh").split("-")[0];

  if (Lang.indexOf(lang) === -1) {
    return "zh";
  }

  return lang;
}

/**
 * Get Item In LocalStorage
 * @param _key
 * @returns
 */
export function getCookieValue(_key: string) {
  return window.sessionStorage.getItem(_key);
}
/**
 * Set Item in LocalStorage
 * @param _key
 * @param _value
 */
export function setCookieValue(_key: string, _value: string) {
  window.sessionStorage.setItem(_key, _value);
}
