export class RoadMapProperties {
    width: number
    height: number
    colNum = 13
    colNumSecondary = 0
    colWidthSecondary = 0
    otherTypeXOffsetX = 0
    cockroachTypeOffserX = 0
    bigOffsetY = 0
    otherOffsetY = 0
    targetOffsetY = 0
    colWidth: number
    dpi: number
    ctx: CanvasRenderingContext2D
    fontSize = 0.7
    lineWidth = 1.5
    isPre = false

    constructor(
        context: CanvasRenderingContext2D,
        w: number,
        h: number,
        cw: number,
        dpi: number,
        cn?: number,
        isPre?: boolean
    ) {
        this.width = w
        this.height = h
        this.colWidth = cw
        this.colNum = cn || 13
        this.dpi = dpi
        this.ctx = context
        this.isPre = isPre || false
    }
}
