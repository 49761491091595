import {
  betOnDragonTiger,
  fetch,
  getCardInfo,
  getDeskInfo,
  getDesks,
  getLogData,
  getNoticeAndLimitInfo,
  login,
  logOut,
} from "@/api";
import {
  MultipleDeskParams,
  NoticeAndLimitParams,
  SingleDeskParams,
} from "@/types/api-params";
import CookiesToken from "@/types/CookiesToken";
import { Dictionary } from "@/types/dictionary";
import {
  getCookieValue,
  getValueByKey,
  getValueOfKeyValue,
  setCookieValue,
} from "@/utils";
import { CurrentDesk, LimitInfo, State, UserState } from "@vue/runtime-core";
import Cookies from "js-cookie";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppActions, AppMutation } from "../types";

const state: UserState = {
  token: getCookieValue(CookiesToken.TOKEN)?.decodeFromB64(),
  coin: parseInt(getCookieValue(CookiesToken.COIN)?.decodeFromB64() ?? "0"),
  userId: getCookieValue(CookiesToken.USER)?.decodeFromB64(),
  infoKey: new Date().getTime(),
  notice: Cookies.get(CookiesToken.NOTICE) ?? "",
  personalLimit:
    getCookieValue(CookiesToken.PERSONAL_LIMIT)
      ?.decodeFromB64()
      .toObject<LimitInfo[]>() || [],
  chips:
    getCookieValue(CookiesToken.CHIPS)?.decodeFromB64().toObject<number[]>() ??
    [],
  currentDesk:
    getCookieValue(CookiesToken.CURRENT_DESK)
      ?.decodeFromB64()
      .toObject<CurrentDesk>() ?? undefined,
  limitInfo:
    getCookieValue(CookiesToken.CURRENT_LIMIT_INFO)
      ?.decodeFromB64()
      .toObject<LimitInfo>() ?? undefined,
  gameOption: parseInt(getCookieValue(CookiesToken.GAME_OPTION) ?? "4"),
  resultCache: new Dictionary<string>(),
  customChip: parseInt(getCookieValue(CookiesToken.CHIP_CUSTOM) ?? "0"),
  showChipToolTip:
    parseInt(getCookieValue(CookiesToken.CHIP_CUSTOM) ?? "0") === 0,
  vipTables:
    getCookieValue(CookiesToken.VIP_TABLES)?.toObject<number[]>() ?? [],
  bcTables: getCookieValue(CookiesToken.BAC_TABLES)?.toObject<number[]>() ?? [],
  dtTables: getCookieValue(CookiesToken.DT_TABLES)?.toObject<number[]>() ?? [],
};

const mutations: MutationTree<UserState> = {
  [AppMutation.INIT_CUSTOM_TABLES](state, payload: number[][]) {
    if (payload.length > 1) {
      state.bcTables = payload[0];
      setCookieValue(CookiesToken.BAC_TABLES, JSON.stringify(payload[0]));
    }

    if (payload.length > 2) {
      state.vipTables = payload[1];
      setCookieValue(CookiesToken.VIP_TABLES, JSON.stringify(payload[1]));
    }

    if (payload.length > 3) {
      state.dtTables = payload[2];
      setCookieValue(CookiesToken.DT_TABLES, JSON.stringify(payload[2]));
    }
  },
  [AppMutation.SET_NOTICE](state, payload: string) {
    state.notice = payload;
    setCookieValue(CookiesToken.NOTICE, payload);
  },
  [AppMutation.SET_PERSONAL_LIMIT](state, payload: LimitInfo[]) {
    state.personalLimit = payload;
    setCookieValue(
      CookiesToken.PERSONAL_LIMIT,
      JSON.stringify(payload).encodeToB64()
    );
  },
  [AppMutation.SET_COIN](state, payload: number) {
    state.coin = payload;
    setCookieValue(CookiesToken.COIN, String(payload).encodeToB64());
  },
  [AppMutation.SET_TOKEN](state, payload: string) {
    state.token = payload;
    setCookieValue(CookiesToken.TOKEN, payload.encodeToB64());
  },
  [AppMutation.SET_USER](state, payload: string) {
    state.userId = payload;
    setCookieValue(CookiesToken.USER, payload.encodeToB64());
  },
  [AppMutation.SET_CURRENT_LIMIT_INFO](state, payload: LimitInfo) {
    state.limitInfo = payload;
    setCookieValue(
      CookiesToken.CURRENT_LIMIT_INFO,
      JSON.stringify(payload).encodeToB64()
    );
  },
  [AppMutation.SET_CHIPS](state, payload: number[]) {
    state.chips = payload;
    setCookieValue(CookiesToken.CHIPS, JSON.stringify(payload).encodeToB64());
  },
  [AppMutation.SET_GAME_OPTION](state, payload: number) {
    state.gameOption = payload;
    setCookieValue(CookiesToken.GAME_OPTION, String(payload));
  },
  [AppMutation.SET_CURRENT_DESK](state, payload: CurrentDesk) {
    state.currentDesk = payload;
    setCookieValue(
      CookiesToken.CURRENT_DESK,
      JSON.stringify(payload).encodeToB64()
    );
  },
  [AppMutation.ADD_RESULT_TO_CACHE](
    state,
    payload: { key: string; value: string }
  ) {
    state.resultCache.Add(payload.key, payload.value);

    if (state.resultCache.Count() > 15) {
      state.resultCache.Remove(state.resultCache.Keys()[0]);
    }
  },
  [AppMutation.SET_CUSTOM_CHIP](state, payload: number) {
    state.customChip = payload;
    state.showChipToolTip = false;
    setCookieValue(CookiesToken.CHIP_CUSTOM, String(payload));
  },
  [AppMutation.HIDE_CUSTOM_CHIP_TOOL_TIP](state) {
    state.showChipToolTip = false;
  },
};

const actions: ActionTree<UserState, State> = {
  [AppActions.USER_LOGIN](
    { commit },
    params: { username: string; password: string }
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      login(params)
        .then((response) => {
          const data = response.data;
          const err = getValueByKey(data, "err");
          if (err) {
            let errorMessage = "Unknown Error";
            if (err === "-1") {
              errorMessage = "参数传递错误";
            } else if (err === "-2") {
              errorMessage = "用户或密码错误";
            } else if (err === "-3") {
              errorMessage = "账号或上级代理被锁定";
            } else if (err === "0") {
              errorMessage = "未知错误";
            } else if (err === "-4") {
              errorMessage = "账号已经登录，请稍后尝试";
            }

            reject(errorMessage);
          } else {
            const keyValues = data.split("&");
            keyValues.forEach((item: string) => {
              if (item.startsWith("notice=")) {
                commit(AppMutation.SET_NOTICE, getValueOfKeyValue(item));
              } else if (item.startsWith("sid=")) {
                commit(AppMutation.SET_TOKEN, getValueOfKeyValue(item));
              } else if (item.startsWith("xianhongArr=")) {
                const xianhongValues = getValueOfKeyValue(item);

                if (xianhongValues) {
                  const xianhArr = xianhongValues.split("$");
                  const limitInfos = new Array<LimitInfo>();
                  xianhArr.forEach((xianhong) => {
                    const values = xianhong.split("#");
                    const limitinfo: LimitInfo = {
                      index: parseInt(values[0]),
                      minBet: parseInt(values[1]),
                      maxBet: parseInt(values[2]),
                    };
                    limitInfos.push(limitinfo);
                  });
                  commit(AppMutation.SET_PERSONAL_LIMIT, limitInfos);
                }
              } else if (item.startsWith("UserPoint=")) {
                const userpoint = parseInt(getValueOfKeyValue(item) || "0");
                commit(AppMutation.SET_COIN, userpoint);
              } else if (item.startsWith("deskArr=")) {
                const _valTables = getValueOfKeyValue(item);
                if (_valTables) {
                  const _arrTables = _valTables.split(";");
                  const _bacTables = _arrTables[0].split(",");
                  const _vipTables = _arrTables[1].split(",");
                  const _dratables = _arrTables[2].split(",");
                  const _tables: number[][] = [[], [], [], []];

                  _bacTables.forEach((_b) => {
                    _tables[0].push(parseInt(_b));
                  });
                  _vipTables.forEach((_b) => {
                    _tables[1].push(parseInt(_b));
                  });
                  _dratables.forEach((_b) => {
                    _tables[2].push(parseInt(_b));
                  });

                  commit(AppMutation.INIT_CUSTOM_TABLES, _tables);
                }
              }
            });

            commit(AppMutation.SET_USER, params.username);
            resolve("");
          }
        })
        .catch(() => {
          reject("网络错误");
        });
    });
  },
  [AppActions.GET_DESKS](
    { commit },
    params: MultipleDeskParams
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      getDesks(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },
  [AppActions.NOTICE_AND_LIMIT_INFO](
    { commit },
    model: NoticeAndLimitParams
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      getNoticeAndLimitInfo(model)
        .then((response) => {
          const data = response.data;
          const err = getValueByKey(data, "err=");
          if (err) {
            resolve("err=2");
          } else {
            //
            const keyValues = data.split("&");
            const limitInfo: LimitInfo = {};
            limitInfo.index = model.xianId;
            const chips = new Array<number>(0, 0, 0, 0, 0);
            keyValues.forEach((item: string) => {
              if (item.startsWith("notice=")) {
                commit("SET_NOTICE", getValueOfKeyValue(item));
              } else if (item.startsWith("UpXian=")) {
                limitInfo.maxBet = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("DownXian=")) {
                limitInfo.minBet = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("DUpXian=")) {
                limitInfo.pairMaxBet = parseInt(
                  getValueOfKeyValue(item) || "0"
                );
              } else if (item.startsWith("DDownXian=")) {
                limitInfo.pairMinBet = parseInt(
                  getValueOfKeyValue(item) || "0"
                );
              } else if (item.startsWith("HUpXian=")) {
                limitInfo.tieMaxBet = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("HDownXian=")) {
                limitInfo.tieMinBet = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("cm1=")) {
                chips[0] = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("cm2=")) {
                chips[1] = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("cm3=")) {
                chips[2] = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("cm4=")) {
                chips[3] = parseInt(getValueOfKeyValue(item) || "0");
              } else if (item.startsWith("cm5=")) {
                chips[4] = parseInt(getValueOfKeyValue(item) || "0");
              }

              commit(AppMutation.SET_CURRENT_LIMIT_INFO, limitInfo);
              commit(AppMutation.SET_CHIPS, chips);
              resolve("");
            });
          }
        })
        .catch(() => {
          reject("网络错误");
        });
    });
  },
  [AppActions.GET_DESK]({ commit }, params: SingleDeskParams): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      getDeskInfo(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          reject("网络错误");
        });
    });
  },
  [AppActions.GET_CARD_INFO]({ commit }, params: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      getCardInfo(params)
        .then((response) => {
          const data = response.data;
          const err = getValueByKey(data, "err");
          if (err) {
            resolve("err=2");
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [AppActions.PLACE_DT_BET]({ commit }, params: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      betOnDragonTiger(params)
        .then((response) => {
          const data = response.data;
          const err = getValueByKey(data, "err");
          if (err) {
            resolve("err=2");
          } else {
            resolve(data);
          }
        })
        .catch(() => {
          reject("网络连接失败");
        });
    });
  },
  [AppActions.LOG_OUT]({ commit }, params: any): Promise<string> {
    commit(AppMutation.SHOW_LOADING);
    return new Promise<string>((resolve, reject) => {
      logOut(params)
        .then((response) => {
          commit(AppMutation.HIDE_LOADING);
          if (response.data === "result=ok") {
            commit(AppMutation.CLEAR_ALL);
            resolve("");
          }
        })
        .catch(() => {
          commit(AppMutation.HIDE_LOADING);
          reject("网络连接失败");
        });
    });
  },
  [AppActions.GET_BET_LOG]({ commit }, params: any): Promise<string> {
    commit(AppMutation.SHOW_LOADING);
    return new Promise<string>((resolve, reject) => {
      getLogData(params)
        .then((response) => {
          commit(AppMutation.HIDE_LOADING);
          resolve(response.data);
        })
        .catch(() => {
          commit(AppMutation.HIDE_LOADING);
          reject("网络连接失败");
        });
    });
  },
  [AppActions.REQUEST_TO_API]({ commit }, params: any): Promise<string> {
    commit(AppMutation.SHOW_LOADING);
    return new Promise<string>((resolve, reject) => {
      fetch(params)
        .then((response) => {
          commit(AppMutation.HIDE_LOADING);
          resolve(response.data);
        })
        .catch(() => {
          commit(AppMutation.HIDE_LOADING);
          reject("网络连接失败");
        });
    });
  },
};

const getters: GetterTree<UserState, State> = {
  user(state): string | undefined {
    return state.userId;
  },
  token(state): string | undefined {
    return state.token;
  },
  personalLimit(state): LimitInfo[] {
    return state.personalLimit;
  },
  coin(state): number {
    return state.coin;
  },
  notice(state): string {
    return state.notice;
  },
  chips(state): number[] {
    return state.chips;
  },
  limitInfo(state): LimitInfo | undefined {
    return state.limitInfo;
  },
  gameOption(state): number {
    return state.gameOption;
  },
  currentDesk(state) {
    return state.currentDesk;
  },
  resultCache(state) {
    return state.resultCache;
  },
  customChip(state) {
    return state.customChip;
  },
  showChipToolTip(state) {
    return state.showChipToolTip;
  },
  vipTables(state) {
    return state.vipTables;
  },
  bacTabbles(state) {
    return state.bcTables;
  },
  dtTables(state) {
    return state.dtTables;
  },
};

export const user: Module<UserState, State> = {
  state,
  mutations,
  actions,
  getters,
};
