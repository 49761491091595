export function useScaleUtils() {
  const getWinSize = () => {
    let winHeight = 0;
    let winWidth = 0;
    if (window.innerWidth) {
      winWidth = window.innerWidth;
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientWidth) {
      winWidth = document.body.clientWidth;
      winHeight = document.body.clientHeight;
    }
    return { winWidth, winHeight };
  };

  const getMobileSize = () => {
    let winHeight = 0;
    let winWidth = 0;
    winWidth = document.documentElement.clientWidth;
    winHeight = document.documentElement.clientHeight;
    return { winHeight, winWidth };
  };

  const setScaleSize = () => {
    const fixedWidth = 1366;
    const fixedHeight = 768;
    const { winHeight, winWidth } = getWinSize();
    let scaleSize = 1;
    const widthScale = winWidth / 16;
    const heightScale = winHeight / 9;
    const fixedScaleWidth = fixedWidth / 16;
    const fixedScaleHight = fixedHeight / 9;

    if (
      (winHeight > fixedHeight && winWidth === fixedWidth) ||
      (winHeight === fixedHeight && winWidth < fixedWidth) ||
      (winHeight > fixedHeight && winWidth < fixedWidth)
    ) {
      scaleSize = winWidth / fixedWidth;
    } else if (
      (winHeight === fixedHeight && winWidth > fixedWidth) ||
      (winHeight < fixedHeight && winWidth === fixedWidth) ||
      (winHeight < fixedHeight && winWidth > fixedWidth)
    ) {
      scaleSize = winHeight / fixedHeight;
    } else if (
      (winHeight > fixedHeight && winWidth > fixedWidth) ||
      (winHeight < fixedHeight && winWidth < fixedWidth)
    ) {
      if (widthScale - fixedScaleWidth < heightScale - fixedScaleHight) {
        scaleSize = winWidth / fixedWidth;
      } else if (widthScale - fixedScaleWidth > heightScale - fixedScaleHight) {
        scaleSize = winHeight / fixedHeight;
      }
    } else if (winHeight === fixedHeight && winWidth === fixedWidth) {
      scaleSize = 1;
    }
    return scaleSize;
  };

  const setMobilScale = () => {
    const fixedWidth = 1366;
    const fixedHeight = 768;
    const { winHeight, winWidth } = getMobileSize();
    let scaleSize = 1;
    const widthScale = winWidth / 16;
    const heightScale = winHeight / 9;
    const fixedScaleWidth = fixedWidth / 16;
    const fixedScaleHight = fixedHeight / 9;

    if (
      (winHeight > fixedHeight && winWidth === fixedWidth) ||
      (winHeight === fixedHeight && winWidth < fixedWidth) ||
      (winHeight > fixedHeight && winWidth < fixedWidth)
    ) {
      scaleSize = winWidth / fixedWidth;
    } else if (
      (winHeight === fixedHeight && winWidth > fixedWidth) ||
      (winHeight < fixedHeight && winWidth === fixedWidth) ||
      (winHeight < fixedHeight && winWidth > fixedWidth)
    ) {
      scaleSize = winHeight / fixedHeight;
    } else if (
      (winHeight > fixedHeight && winWidth > fixedWidth) ||
      (winHeight < fixedHeight && winWidth < fixedWidth)
    ) {
      if (widthScale - fixedScaleWidth < heightScale - fixedScaleHight) {
        scaleSize = winWidth / fixedWidth;
      } else if (widthScale - fixedScaleWidth > heightScale - fixedScaleHight) {
        scaleSize = winHeight / fixedHeight;
      }
    } else if (winHeight === fixedHeight && winWidth === fixedWidth) {
      scaleSize = 1;
    }
    return scaleSize;
  };

  return {
    setMobilScale,
    setScaleSize,
  };
}
