export const ACT = {
  TRIAL: 11,
  LOGIN: 1,
  SINGLE_DESK_INFO: 3,
  // CARD_INFO: 4,
  CARD_INFO: 10,
  LOGOUT: 17,
  MULTIPLE_DESK_INFO: 2,
  GET_NOTICE_AND_LIMIT: 9,
  BACCARAT_BET: 6,
  DT_BET: 7,
  CHANGE_PASS: 12,
  GET_LOG: 13,
  GET_LOG_DETAILS: 15,
  CLIENT_VERSION: 16,
  VIDEO_INFOS: 18,
};

export const GAME_TYPE = {
  BACCARAT: 4,
  DRAGON_TIGER: 2,
  CARD_FIRST: 5,
  ALL: 3,
};

export const BET = {
  CODE: {
    OK: -1,
    INSUFICIENT_BALANCE: 0,
    NO_BET_AREA_SELECTED: 1,
    BELOW_MINIMUM: 2,
    ABOVE_MAXIMUM: 3,
    THERE_IS_NO_BET: 4,
    BET_FAILED_BET_AGAIN: 5,
  },
  MESSAGE: {
    ABOVE_MAXIMUM: "超出最大限红",
    BELOW_MINIMUM: "闲投注金额不能小于最小投注",
    THERE_IS_NO_BET: "你还没有投注^_^！",
    BET_FAILED_BET_AGAIN: "下注失败！请重新下注!",
    NO_BET_AREA_SELECTED: "请先选择要投的区域",
    INSUFICIENT_BALANCE: "余额不足",
  },
  // TYPE: {
  //     DRAGON: 0,
  //     TIE: 1,
  //     TIGER: 2,
  //     BANKER: 3,
  //     PLAYER: 4,
  //     PLAYER_PAIR: 5,
  //     BANKER_PAIR: 6
  // }
};

/**
 * Game Result
 */
export const RESULT = {
  RED: 1,
  BLUE: 3,
  GREEN: 2,
};

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
  CLOSED: 1,
  END_OF_ROUND: 4,
};

export enum BetType {
  BANKER = 0,
  TIE = 1,
  PLAYER = 2,
  TIGER = 3,
  DRAGON = 4,
  DT_TIE = 5,
  BANKER_PAIR = 6,
  PLAYER_PAIR = 7,
}

export const Lang = ["en", "kr", "zh"];
